/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { GetAllBookedOrderByVendorId } from "../API/EventApi";
import NoDataFound from "../common/NodataFound";
import DashboardMenu from "./DashboardMenu";
import { format } from "date-fns";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { FaEye } from "react-icons/fa6";
import ScrollToTop from "../ScrollToTop";

const ViewAllBooking = () => {
  // ================ Get data by Id============
  const [EventData, setEventData] = useState();
  const [filterdata, setfilterdata] = useState([]);
  const [search, setsearch] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const fetchData = async () => {
    try {
      const UserData = await GetAllBookedOrderByVendorId();
      setEventData(UserData);
      setfilterdata(UserData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  usePageSEO(
    "Love My Show | View All Booking", // title
    "Love My Show | View All Booking", // description
    ["Love My Show | View All Booking "] // keywords
  );

  const navigate = useNavigate();
  const actionTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon={<FaEye />}
          className="border border-blue-600 text-blue-600 mr-2 rounded-full py-2.5"
          onClick={() => {
            navigate(`/event/all-bookings/${rowData.Id}`);
          }}
        />
      </div>
    );
  };
  useEffect(() => {
    const mySearch = EventData?.filter(
      (item) =>
        item.UserName && item.UserName.toLowerCase().match(search.toLowerCase())
    );
    setfilterdata(mySearch);
  }, [search]);

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2">
                    <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                      All Booking
                    </h1>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center my-40">
                      <ClipLoader
                        color={"#DC3558"}
                        loading={loading}
                        size={40}
                      />
                    </div>
                  ) : (
                    <>
                      {EventData?.length === 0 ? (
                        <NoDataFound />
                      ) : (
                        <>
                          <div class="relative overflow-x-auto shadow-lg">
                            {EventData && EventData.length > 0 ? (
                              <DataTable
                                value={filterdata}
                                tableStyle={{
                                  minWidth: "50rem",
                                  border: "1px solid #e0e0e0",
                                }}
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                emptyMessage="No Data found"
                                globalFilter={search}
                                header={
                                  <div className="flex justify-between p-ai-center">
                                    <span className="p-input-icon-left">
                                      <i className="pi pi-search" />
                                      <InputText
                                        type="text"
                                        className="text-start me-auto text-sm border-2 py-2 pl-2 md:pr-20 pr-5"
                                        onInput={(e) =>
                                          setsearch(e.target.value)
                                        }
                                        placeholder="Search"
                                      />
                                    </span>
                                  </div>
                                }
                              >
                                <Column
                                  field="Id"
                                  header="#"
                                  sortable
                                  className="border border-stroke"
                                />
                                <Column
                                  field="UserName"
                                  header="User Name"
                                  sortable
                                  className="border border-stroke"
                                />
                                <Column
                                  field="UserEmail"
                                  header="User Email"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="UserPhone"
                                  header="User Phone"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="EventName"
                                  header="Event Name"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="Country"
                                  header="Country"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="City"
                                  header="City"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="TicketName"
                                  header="Ticket Name"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="Price"
                                  header="Price"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="Qty"
                                  header="Ticket Quantity"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="Charge"
                                  header="Ticket Charge"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="Total"
                                  header="Total"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="PaymentMethod"
                                  header="Payment Method"
                                  className="border border-stroke"
                                />
                                <Column
                                  field="PaymentStatus"
                                  header="Payment Status"
                                  className="border border-stroke"
                                  body={(rowData) => (
                                    <span
                                      className={`text-xs font-medium px-2.5 py-0.5 rounded-full ${
                                        rowData.PaymentStatus === 1
                                          ? "bg-green-600 text-white"
                                          : "bg-red-600 text-white"
                                      }`}
                                    >
                                      {rowData.PaymentStatus === 1
                                        ? "Success"
                                        : "Failed"}
                                    </span>
                                  )}
                                />
                                <Column
                                  field="EntDt"
                                  header="Entry Date"
                                  className="border border-stroke"
                                  body={(rowData) =>
                                    format(
                                      new Date(rowData.EntDt),
                                      "dd/MM/yyyy"
                                    )
                                  }
                                />
                                <Column
                                  header="Action"
                                  className="border border-stroke"
                                  body={actionTemplate}
                                />
                              </DataTable>
                            ) : (
                              <div className="mx-3 my-3 py-16 bg-slate-300 font-bold text-2xl text-bodydark2 text-center">
                                No Booking Found
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllBooking;
