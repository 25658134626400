/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getEventByCityIdCategoryId } from "../API/EventApi";
import ClipLoader from "react-spinners/ClipLoader"; // Importing the spinner
import Config from "../API/Config";
import NoDataFound from "../common/NodataFound";
import { FaCirclePlay } from "react-icons/fa6";
import { FcCalendar } from "react-icons/fc";
import { format } from "date-fns";
import ScrollToTop from "../ScrollToTop";
import ReactPaginate from "react-paginate";
import { FaDollarSign, FaRupeeSign } from "react-icons/fa";

const EventsCategory = () => {
  usePageSEO(
    "Love My Show ", // title
    "Love My Show ", // description
    ["Love My Show  "] // keywords
  );

  const { Id } = useParams();
  const { Slug } = useParams();
  const [EventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const CityId = Config.getCityIdData();
  const getCountrySlug = Config.getCountrySlug();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        if (CityId) {
          const result = await getEventByCityIdCategoryId(CityId, Id);
          setEventData(result);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchEventData();
  }, [CityId, Id]);
  usePageSEO(
    EventData ? `Love My Show | ${Slug}` : "Love My Show | Booking - Event",
    EventData ? `Love My Show | ${Slug}` : "Love My Show | Booking - Event",
    [EventData ? `Love My Show | ${Slug}` : "Love My Show | Booking - Event"]
  );
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil(EventData.length / itemsPerPage);

  const activeEvents = EventData?.filter((val) => val.Status === 1) || [];
  return (
    <div className="dark:bg-boxdark-2 ">
      <ScrollToTop />
      <section className="  md:px-3 dark:bg-boxdark-2 dark:text-white">
        <div className="container mx-auto">
          {/* ==============================Events In City=================================*/}
          <div className="pb-20 md:pb-5">
            <h1 className="py-6 mx-4 text-2xl font-bold capitalize">
              {Slug} Events
            </h1>
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#DC3558"} loading={loading} size={30} />
              </div>
            ) : EventData?.length > 0 ? (
              <div>
                {loading ? (
                  <div className="flex justify-center items-center py-40 dark:bg-boxdark-2">
                    <ClipLoader color={"#DC3558"} loading={loading} size={30} />
                  </div>
                ) : activeEvents?.length > 0 ? (
                  <div className="">
                    <div className="grid lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 foldsmall:grid-cols-1 xs:grid-cols-1">
                      {EventData?.map((val, index) => (
                        <div className="px-2 pb-5" key={index}>
                          <div className="bg-white dark:bg-boxdark-2 dark:border dark:border-zinc-600 shadow-lg rounded-md">
                            <Link
                              to={`/event-detail/${val.EventSlug}/${val.Id}`}
                              className=""
                            >
                              <img
                                src={val.Thumb}
                                alt=""
                                className="xl:h-80 lg:h-80 surface:h-72 rounded-md h-72 md:h-60 w-full rounded-b-none"
                              />
                              <div className="px-3 py-3">
                                <h1 className="md:font-semibold pt-1 pb-1 mb-1 md:text-base text-gray-800 leading-8 text-sm line-clamp-1 dark:text-white">
                                  {val.EventName}
                                </h1>

                                <p className="text-sm line-clamp-1 mb-3 text-gray-500 dark:text-gray-300">
                                  {val.CategoryTitle}
                                </p>
                                <div className="mt-1">
                                  <div className="flex mb-3 md:items-center">
                                    <div className="flex md:text-sm text-xs font-semibold">
                                      <p className="text-sm mr-1">
                                        <FcCalendar />
                                      </p>
                                      <h1 className="line-clamp-1 text-xs text-gray-400  font-normal">
                                        {format(
                                          new Date(val.Dates.EventDate),
                                          "dd/MM/yyyy"
                                        )}
                                      </h1>
                                    </div>
                                    <div className="flex md:items-center md:text-sm text-xs font-semibold ml-auto">
                                      <p className="md:text-xl text-sm mx-1 me-2 text-red-600">
                                        <FaCirclePlay />
                                      </p>
                                      <h1 className="line-clamp-1 text-xs font-medium">
                                        {val.ArtistTitle}
                                      </h1>
                                    </div>
                                  </div>
                                  <div className="flex text-sm font-semibold pt-1 dark:text-white">
                                    <h1 className="my-auto flex items-center">
                                      {getCountrySlug === "India" ? (
                                        <>
                                          <span className="text-base">
                                            <FaRupeeSign />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="text-base">
                                            <FaDollarSign />
                                          </span>
                                        </>
                                      )}{" "}
                                      {val.Price.TicketPrice}
                                    </h1>
                                    <button className="ml-auto bg-[#F84464] md:hover:bg-black rounded-full  px-3 py-1">
                                      <Link
                                        to={`/event-detail/${val.EventSlug}/${val.Id}`}
                                        className="text-xs text-white font-medium"
                                      >
                                        BUY NOW
                                      </Link>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>

                    {activeEvents?.length > 0 ? (
                      <div className="flex justify-center mt-4 dark:text-white">
                        <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLinkClassName="page-link"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <NoDataFound />
                )}
              </div>
            ) : (
              <NoDataFound />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default EventsCategory;
