import React from "react";
import FailImage from "../../Images/fail.gif";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ScrollToTop from "../ScrollToTop";
const OrderFail = () => {
  usePageSEO(
    "Love My Show | Order - Fail", // title
    "Love My Show | Order - Fail", // description
    ["Love My Show | Order - Fail "] // keywords
  );
  return (
    <div>
      <ScrollToTop />
      <div className="py-10 container mx-auto ">
        {" "}
        <div>
          <div className="bg-white dark:bg-boxdark-2 dark:text-white shadow-md border rounded px-8 pt-6 pb-8 mb-4 mx-5">
            <h2 className="text-2xl border-b font-semibold mb-4 text-center pb-2">
              Ticket Book Failed !
            </h2>
            <div>
              <img
                src={FailImage}
                alt=""
                className="lg:w-[50%] w-[100%] mx-auto"
              />
            </div>
            <p className="border-t pt-1.5 text-lg text-center">
              Please try again letter
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFail;
