/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import succesImage from "../../Images/success.gif";
import { Link, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { FaCalendarCheck, FaLocationDot } from "react-icons/fa6";
import ScrollToTop from "../ScrollToTop";
import Config from "../API/Config";
import { FaDollarSign, FaRupeeSign } from "react-icons/fa";

const OrderSuccess = () => {
  usePageSEO(
    "Love My Show  | Order Success", // title
    "Love My Show  | Order Success", // description
    ["Love My Show  | Order Success "] // keywords
  );
  const location = useLocation();
  const getCountrySlug = Config.getCountrySlug();
  const { EventAddressData1, CoupenAmount } = location.state || {};
  const CoupenAmount1 = JSON.stringify(CoupenAmount);
  const [EventAddressData, setEventAddressData] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  const fetchEventAddressData = async () => {
    try {
      setEventAddressData(EventAddressData1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchEventAddressData();
  }, []);
  const startDate = new Date(EventAddressData?.event?.EventStartDateTime);
  const formattedStartDate = `${startDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(startDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${startDate.getFullYear()}`;

  const calculateTotalAmount = () => {
    const subtotal = EventAddressData?.total?.subTotal || 0;
    const charge = EventAddressData?.total?.charge || 0;
    const discount = CoupenAmount1 || 0;
    return subtotal + charge - discount;
  };
  return (
    <div className="py-5 container mx-auto ">
      <ScrollToTop />
      <div>
        <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
          <h2 className="text-2xl mb-4 text-center py-4 text-green-700">
            Ticket Book Successfully
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 ">
            <div className="my-auto">
              <img
                src={succesImage}
                alt=""
                className="lg:w-[85%] w-[100%] mx-auto my-auto"
              />
            </div>
            <div className="my-auto">
              <h2 className="text-lg p-2 py-1  text-white font-bold bg-themecolor1">
                Booking Details
              </h2>
              <div className="border rounded-md">
                <div className="grid grid-cols-2 md:grid-cols-2 border border-gray-300 mx-2 mt-2 rounded-md  mb-3">
                  <div className="text-black dark:text-white py-2 px-5 border-b dark:border-zinc-600 ">
                    <h1 className="font-semibold">
                      {EventAddressData?.event?.EventName}
                    </h1>
                    <p className="text-sm text-gray-500 dark:text-white">
                      <span>Ticket Qty: </span>
                      {EventAddressData?.total?.Qty}
                    </p>
                  </div>

                  <h1 className="py-2 pr-5 border-b dark:border-zinc-600 flex justify-end items-center">
                    {getCountrySlug === "India" ? (
                      <>
                        <span className="text-sm">
                          <FaRupeeSign />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-sm">
                          <FaDollarSign />
                        </span>
                      </>
                    )}{" "}
                    {EventAddressData?.total?.Price}
                  </h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-1 border border-gray-300 mx-2 rounded-md py-2 px-5 text-sm mb-3">
                  <div className="flex items-center">
                    <FaCalendarCheck className="text-xl mr-2" />
                    <div>
                      <p className="mt-2">
                        <b>Event Date:</b>
                      </p>
                      <p className=" pb-2 text-xs text-gray-500 dark:text-white">
                        {formattedStartDate}- (
                        {EventAddressData?.date?.EventStartTime} -{" "}
                        {EventAddressData?.date?.EventEndTime})
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <FaLocationDot className="text-xl mr-2" />
                    <div>
                      <p className="mt-2">
                        <b>Venue</b>
                      </p>
                      <p className=" pb-2 text-xs text-gray-500 dark:text-white">
                        {EventAddressData?.address?.Location} ,
                        {EventAddressData?.address?.CityTitle} ,
                        {EventAddressData?.address?.CountryTitle}
                      </p>
                    </div>
                  </div>
                  <h1 className="text-lg border-t dark:border-zinc-600 py-2 w-48 md:w-[100%] text-sm">
                    {EventAddressData?.ticket?.TicketName}(
                    {EventAddressData?.total?.Price}) :{" "}
                    {EventAddressData?.total?.Qty} ticket(s)
                  </h1>
                </div>
                <div className="grid grid-cols-2 mt-4">
                  <p className="px-5">Sub-total</p>
                  <p className="flex justify-end px-5 items-center">
                    {" "}
                    {getCountrySlug === "India" ? (
                      <>
                        <span className="text-sm">
                          <FaRupeeSign />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-sm">
                          <FaDollarSign />
                        </span>
                      </>
                    )}{" "}
                    {EventAddressData?.total?.subTotal}
                  </p>
                </div>
                <div className="card py-2 px-5">
                  <button
                    onClick={toggleReadMoreLess}
                    className="flex w-full border-b dark:border-zinc-600 pb-2"
                  >
                    Booking Fee
                    {isShowMore ? (
                      <MdKeyboardArrowUp className="mt-1" />
                    ) : (
                      <MdKeyboardArrowDown className="mt-1" />
                    )}
                    <h1 className="flex ml-auto items-center">
                      {" "}
                      {getCountrySlug === "India" ? (
                        <>
                          <span className="text-sm">
                            <FaRupeeSign />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="text-sm">
                            <FaDollarSign />
                          </span>
                        </>
                      )}{" "}
                      {EventAddressData?.total?.charge}
                    </h1>
                  </button>
                  {isShowMore && (
                    <div className="border-b dark:border-zinc-600 pb-1">
                      <div className="flex pt-2">
                        <p className="text-sm w-full">Base Price</p>
                        <h1 className="flex items-center">
                          {getCountrySlug === "India" ? (
                            <>
                              <span className="text-sm">
                                <FaRupeeSign />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="text-sm">
                                <FaDollarSign />
                              </span>
                            </>
                          )}{" "}
                          {EventAddressData?.total?.subtotalExcludingGSTTOTAL.toFixed(
                            2
                          )}
                        </h1>
                      </div>
                      <div className="flex pb-5">
                        <p className="text-xs w-full">
                          Integrated GST %{" "}
                          {EventAddressData?.total?.gstPercentage}
                        </p>
                        <h1 className="flex items-center">
                          {getCountrySlug === "India" ? (
                            <>
                              <span className="text-sm">
                                <FaRupeeSign />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="text-sm">
                                <FaDollarSign />
                              </span>
                            </>
                          )}{" "}
                          {EventAddressData?.total?.gstAmount.toFixed(2)}
                        </h1>
                      </div>
                    </div>
                  )}
                </div>
                {CoupenAmount1 === 0 ? (
                  ""
                ) : (
                  <div className="grid grid-cols-2">
                    <p className="px-5">Coupon Discount</p>
                    <p className="flex justify-end px-5 items-center">
                      {getCountrySlug === "India" ? (
                        <>
                          <span className="text-sm">
                            <FaRupeeSign />
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="text-sm">
                            <FaDollarSign />
                          </span>
                        </>
                      )}{" "}
                      {CoupenAmount1}
                    </p>
                  </div>
                )}
                <div className="flex px-5 pb-4 border-b">
                  <h1 className="w-full">Total Amount</h1>
                  <h1 className="flex items-center">
                    {getCountrySlug === "India" ? (
                      <>
                        <span className="text-sm">
                          <FaRupeeSign />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-sm">
                          <FaDollarSign />
                        </span>
                      </>
                    )}{" "}
                    {calculateTotalAmount()}
                  </h1>
                </div>

                <Link to={"/orderhistory"}>
                  <button
                    type="button"
                    className="bg-themecolor1 text-white   block m-auto my-4 py-2.5 px-5 rounded-md"
                  >
                    View Ticket
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSuccess;
