// Editor.js
import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({ values, onChange }) => {
  const handleEditorChange = (content, editor) => {
    onChange(content);
  };

  return (
    <Editor
      apiKey="y54f4xfrpjsuntawyo7htxny941ymaxq05ecl967569dtxqd" // Replace with your TinyMCE API key
      initialValue={values}
      init={{
        height: 350,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor advcode",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code",
          "code",
          "image",
          "media",
          "table",
          "lists",
          "link",
          "wordcount",
          "emoticons",
          "charmap",
          "insertdatetime",
          "forecolor",
          "fontsize",
          "lineheight",
          "preview",
        ],
        toolbar:
          "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | removeformat | code | image | media | table | numlist bullist | link | wordcount | emoticons | charmap | insertdatetime | forecolor | fontsize | lineheight | preview",
        file_picker_types: "image",
        file_picker_callback: (cb, value, meta) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");

          input.addEventListener("change", (e) => {
            const file = e.target.files[0];

            if (file) {
              const reader = new FileReader();
              reader.onload = () => {
                const id = "blobid" + new Date().getTime();
                const blobCache =
                  window.tinymce.activeEditor.editorUpload.blobCache;
                const blobInfo = blobCache.create(
                  id,
                  file,
                  reader.result.split(",")[1]
                );
                blobCache.add(blobInfo);
                cb(blobInfo.blobUri(), { title: file.name });
              };
              reader.readAsDataURL(file);
            }
          });

          input.click();
        },
      }}
      onEditorChange={handleEditorChange}
    />
  );
};

export default TinyMCEEditor;
