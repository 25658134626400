// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import { MdOutlinePayments } from "react-icons/md";
// import { FaCalendar, FaMinus, FaPlus } from "react-icons/fa6";
// import usePageSEO from "../hoosk/usepageSEO/Index";
// import { Link, useParams, useNavigate } from "react-router-dom";
// import {
//   PostEventBookTicketData,
//   getEventBookTicketData,
// } from "../API/EventApi";
// import { Tooltip } from "react-tooltip";
// import { useFormik } from "formik";
// import { SiBookmyshow } from "react-icons/si";
// import { FaAddressCard, FaDollarSign, FaRupeeSign } from "react-icons/fa";
// import ClipLoader from "react-spinners/ClipLoader";
// import FormLoader from "../common/FormLoader";
// import ScrollToTop from "../ScrollToTop";
// import Config from "../API/Config";
// import soldout from "../../Images/Sold-Out.png";

// const BookingSteps3 = () => {
//   const getCountrySlug = Config.getCountrySlug();
//   usePageSEO(
//     "Love My Show | Book Event", // title
//     "Love My Show | Book Event", // description
//     ["Love My Show | Book Event"] // keywords
//   );
//   const [loading, setLoading] = useState(true); // Loading state
//   const [selectedTicketId, setSelectedTicketId] = useState(null);
//   const [count, setCount] = useState(1);
//   const { EventId, AddressId, DateId } = useParams();
//   const [EventAddressData, setEventAddressData] = useState([]);
//   const [limitReached, setLimitReached] = useState(false); // Initialize correctly
//   const [availableTickets, setAvailableTickets] = useState(10);
//   const [TicketQuantity, setTicketQuantity] = useState(10);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchEventAddressData = async () => {
//       try {
//         const result = await getEventBookTicketData(DateId);
//         setEventAddressData(result);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false); // Set loading to false after data is fetched
//       }
//     };
//     fetchEventAddressData();
//   }, [DateId]);

//   // const incNum = () => {
//   //   if (count < Math.min(TicketQuantity, availableTickets)) {
//   //     setCount(count + 1);
//   //     setLimitReached(false);
//   //   } else {
//   //     setLimitReached(true);
//   //   }
//   // };

//   // const decNum = () => {
//   //   if (count > 1) {
//   //     setCount(count - 1);
//   //     setLimitReached(false);
//   //   }
//   // };

//   const incNum = () => {
//     if (count < Math.min(TicketQuantity, availableTickets)) {
//       setCount(count + 1);
//       setLimitReached(false);
//     } else {
//       setLimitReached(true);
//     }
//   };

//   const decNum = () => {
//     if (count > 1) {
//       setCount(count - 1);
//       setLimitReached(false);
//     }
//   };

//   const handleAddButtonClick = (ticketId, TicketQuantity) => {
//     setSelectedTicketId(ticketId);
//     setTicketQuantity(TicketQuantity);
//     setCount(1); // Reset the quantity when a new ticket is selected
//     setLimitReached(false); // Reset the limit reached flag
//   };
//   const [isFormLoading, setIsFormLoading] = useState(false);
//   const formik = useFormik({
//     initialValues: {
//       Id: EventId,
//       AddressId: AddressId,
//       DateId: DateId,
//       TicketId: selectedTicketId,
//       Qty: count,
//     },
//     onSubmit: async (values) => {
//       setIsFormLoading(true);
//       try {
//         const response = await PostEventBookTicketData(values);

//         const data = response.responseData;
//         if (response.status === true) {
//           setAvailableTickets(availableTickets - count);
//           navigate(
//             `/event/bookingsteps/${EventId}/${AddressId}/${DateId}/${selectedTicketId}`,
//             { state: data }
//           );
//         }
//       } catch (error) {
//         console.error("Error:", error);
//       } finally {
//         setIsFormLoading(false); // Set loading state to false when submission ends
//       }
//     },
//   });

//   useEffect(() => {
//     formik.setFieldValue("TicketId", selectedTicketId);
//     formik.setFieldValue("Qty", count);
//   }, [selectedTicketId, count]);

//   return (
//     <div className="py-5 container mx-auto ">
//       <ScrollToTop />
//       {isFormLoading && <FormLoader loading={isFormLoading} />}
//       <div className="w-full py-4 px-4">
//         <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
//           <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor1"></div>
//           <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-900 dark:bg-white transition-all duration-500"></div>
//           <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor1 rounded-full place-items-center">
//             <SiBookmyshow className="w-5 h-5" />
//           </div>
//           <div class="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor1 rounded-full place-items-center">
//             <FaCalendar className="w-5 h-5" />
//           </div>
//           <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor1 rounded-full place-items-center">
//             <FaAddressCard className="w-5 h-5" />
//           </div>
//           <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gray-300 rounded-full place-items-center">
//             <MdOutlinePayments className="w-5 h-5" />
//           </div>
//         </div>
//         <div className="my-10">
//           {loading ? (
//             <div className="flex justify-center items-center my-40">
//               <ClipLoader color={"#DC3558"} loading={loading} size={30} />
//             </div>
//           ) : (
//             <>
//               {EventAddressData.Tickets.length === 0 ? (
//                 <img src={soldout} alt="" className="w-96 mx-auto" />
//               ) : (
//                 <>
//                   {EventAddressData?.Tickets?.map((val) => (
//                     <div
//                       key={val.Id}
//                       className="mx-auto w-full px-0 py-5 bg-gray-100 shadow-lg relative cursor-pointer lg:w-[40%] transition-all duration-300 ease-in-out dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 rounded-lg"
//                     >
//                       <div className="grid grid-cols-2">
//                         <div className="">
//                           <h1 className="px-5 font-semibold mb-1">
//                             {val.TicketName}
//                           </h1>
//                           <p className="px-5 text-green-600 font-bold text-sm flex dark:text-white items-center">
//                             {getCountrySlug === "India" ? (
//                               <>
//                                 <span className="text-base">
//                                   <FaRupeeSign />
//                                 </span>
//                               </>
//                             ) : (
//                               <>
//                                 <span className="text-base">
//                                   <FaDollarSign />
//                                 </span>
//                               </>
//                             )}{" "}
//                             {val.TicketAmount}
//                           </p>
//                         </div>
//                         <div className="flex justify-end items-center">
//                           {selectedTicketId === val.Id ? (
//                             <>
//                               <button
//                                 onClick={decNum}
//                                 className="border text-thebg-themecolor1 text-xl rounded-full mx-2 h-8 w-8 border-themecolor1"
//                               >
//                                 <FaMinus className="ml-[5px]" />
//                               </button>
//                               <div className="mt-1">{count}</div>
//                               <button
//                                 onClick={incNum}
//                                 className={`border text-thebg-themecolor1 text-xl rounded-full mx-2 h-8 w-8 border-themecolor1 ${
//                                   count >=
//                                   Math.min(TicketQuantity, availableTickets)
//                                     ? "cursor-not-allowed opacity-50"
//                                     : ""
//                                 }`}
//                                 disabled={
//                                   count >=
//                                   Math.min(TicketQuantity, availableTickets)
//                                 }
//                                 data-tooltip-id={`tooltip-${val.Id}`}
//                               >
//                                 <FaPlus className="ml-[5px]" />
//                               </button>
//                               {limitReached && (
//                                 <Tooltip
//                                   id={`tooltip-${val.Id}`}
//                                   place="top"
//                                   effect="solid"
//                                 >
//                                   You cannot add more than{" "}
//                                   {Math.min(TicketQuantity, availableTickets)}{" "}
//                                   tickets.
//                                 </Tooltip>
//                               )}
//                             </>
//                           ) : (
//                             <button
//                               onClick={() =>
//                                 handleAddButtonClick(val.Id, val.TicketQuantity)
//                               }
//                               className="bg-green-500 text-white rounded-sm text-xs px-4 py-1 mx-2"
//                             >
//                               Add
//                             </button>
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   ))}
//                 </>
//               )}
//             </>
//           )}
//         </div>
//         <div className="flex justify-between md:w-1/2 mx-auto">
//           <div>
//             <Link
//               to={`/event/bookingsteps/${EventId}/${AddressId}`}
//               className="my-2 py-2 px-5 rounded flex mx-auto bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:scale-105 transition-all"
//             >
//               Previous
//             </Link>
//           </div>
//           <div>
//             {selectedTicketId === null ? (
//               <>
//                 <button
//                   data-tooltip-id="nextButtonTooltip"
//                   className="my-2 py-2 px-5 rounded mx-auto bg-black bg-opacity-85 cursor-not-allowed text-white hover:bg-opacity-95 transition-all"
//                 >
//                   Next
//                 </button>
//                 <Tooltip id="nextButtonTooltip" place="left" effect="solid">
//                   Please select Ticket
//                 </Tooltip>
//               </>
//             ) : (
//               <form onSubmit={formik.handleSubmit}>
//                 <button
//                   type="submit"
//                   className="my-2 py-2 px-5 rounded flex mx-auto bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:scale-105 transition-all"
//                 >
//                   Next
//                 </button>
//               </form>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BookingSteps3;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdOutlinePayments } from "react-icons/md";
import { FaCalendar, FaMinus, FaPlus } from "react-icons/fa6";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  PostEventBookTicketData,
  getEventBookTicketData,
} from "../API/EventApi";
import { Tooltip } from "react-tooltip";
import { useFormik } from "formik";
import { SiBookmyshow } from "react-icons/si";
import { FaAddressCard, FaDollarSign, FaRupeeSign } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import Config from "../API/Config";
import soldout from "../../Images/Sold-Out.png";

const BookingSteps3 = () => {
  const { EventId, AddressId, DateId } = useParams();
  const navigate = useNavigate();
  const countrySlug = Config.getCountrySlug();
  const [loading, setLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [count, setCount] = useState(1);
  const [EventAddressData, setEventAddressData] = useState([]);
  const [availableTickets, setAvailableTickets] = useState(10);
  const [ticketQuantity, setTicketQuantity] = useState(10);
  const [limitReached, setLimitReached] = useState(false);

  // SEO metadata
  usePageSEO("Love My Show | Book Event", "Book your event tickets", [
    "Book Event",
  ]);

  // Fetch event ticket data
  useEffect(() => {
    const fetchEventAddressData = async () => {
      try {
        const result = await getEventBookTicketData(DateId);
        setEventAddressData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEventAddressData();
  }, [DateId]);

  // Form handling with Formik
  const formik = useFormik({
    initialValues: {
      Id: EventId,
      AddressId: AddressId,
      DateId: DateId,
      TicketId: selectedTicketId,
      Qty: count,
    },
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        const response = await PostEventBookTicketData(values);
        if (response.status === true) {
          setAvailableTickets((prev) => prev - count);
          navigate(
            `/event/bookingsteps/${EventId}/${AddressId}/${DateId}/${selectedTicketId}`,
            {
              state: response.responseData,
            }
          );
        }
      } catch (error) {
        console.error("Error submitting the form:", error);
      } finally {
        setIsFormLoading(false);
      }
    },
  });

  // Ticket selection logic
  const handleAddButtonClick = (ticketId, ticketQty) => {
    setSelectedTicketId(ticketId);
    setTicketQuantity(ticketQty);
    setCount(1);
    setLimitReached(false);
  };

  const increaseQuantity = () => {
    if (count < Math.min(ticketQuantity, availableTickets)) {
      setCount((prev) => prev + 1);
      setLimitReached(false);
    } else {
      setLimitReached(true);
    }
  };

  const decreaseQuantity = () => {
    if (count > 1) {
      setCount((prev) => prev - 1);
      setLimitReached(false);
    }
  };

  // Dynamically update form values
  useEffect(() => {
    formik.setFieldValue("TicketId", selectedTicketId);
    formik.setFieldValue("Qty", count);
  }, [selectedTicketId, count]);

  return (
    <div className="container mx-auto py-5">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="px-4 py-4">
        {/* Booking Progress Steps */}
        <div className="relative flex items-center justify-between mx-auto md:w-1/2">
          <div className="absolute left-0 top-1/2 w-full h-0.5 bg-themecolor1"></div>
          <div className="absolute left-0 top-1/2 w-full h-0.5 bg-gray-900 dark:bg-white transition duration-500"></div>
          {[SiBookmyshow, FaCalendar, FaAddressCard, MdOutlinePayments].map(
            (Icon, index) => (
              <div
                key={index}
                className={`relative grid w-10 h-10 font-bold text-white transition-all duration-300 rounded-full place-items-center ${
                  index < 3 ? "bg-themecolor1" : "bg-gray-300"
                }`}
              >
                <Icon className="w-5 h-5" />
              </div>
            )
          )}
        </div>

        {/* Tickets Section */}
        <div className="my-10">
          <p className="text-center font-semibold dark:text-white text-xl mb-4">
            Select Event Ticket
          </p>
          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : EventAddressData.Tickets.length === 0 ? (
            <img src={soldout} alt="Sold Out" className="w-96 mx-auto" />
          ) : (
            EventAddressData.Tickets.map((ticket) => (
              <div
                key={ticket.Id}
                className="mx-auto w-full px-0 py-5 bg-gray-100 dark:bg-boxdark-2 dark:text-white dark:border-zinc-600 lg:w-[40%] shadow-lg rounded-lg transition-all duration-300 ease-in-out cursor-pointer"
              >
                <div className="grid grid-cols-2">
                  <div className="px-5">
                    <h1 className="font-semibold mb-1">{ticket.TicketName}</h1>
                    <p className="text-green-600 dark:text-white flex items-center font-bold text-sm">
                      {countrySlug === "India" ? (
                        <FaRupeeSign />
                      ) : (
                        <FaDollarSign />
                      )}{" "}
                      {ticket.TicketAmount}
                    </p>
                  </div>
                  <div className="flex justify-end items-center">
                    {selectedTicketId === ticket.Id ? (
                      <>
                        <button
                          onClick={decreaseQuantity}
                          className="mx-2 h-8 w-8 text-xl border border-themecolor1 rounded-full"
                        >
                          <FaMinus className="ml-1" />
                        </button>
                        <div className="mt-1">{count}</div>
                        <button
                          onClick={increaseQuantity}
                          className={`mx-2 h-8 w-8 text-xl border border-themecolor1 rounded-full ${
                            count >= Math.min(ticketQuantity, availableTickets)
                              ? "opacity-50 cursor-not-allowed"
                              : ""
                          }`}
                          disabled={
                            count >= Math.min(ticketQuantity, availableTickets)
                          }
                          data-tooltip-id={`tooltip-${ticket.Id}`}
                        >
                          <FaPlus className="ml-1" />
                        </button>
                        {limitReached && (
                          <Tooltip
                            id={`tooltip-${ticket.Id}`}
                            place="top"
                            effect="solid"
                          >
                            You cannot add more than{" "}
                            {Math.min(ticketQuantity, availableTickets)}{" "}
                            tickets.
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <button
                        onClick={() =>
                          handleAddButtonClick(ticket.Id, ticket.TicketQuantity)
                        }
                        className="bg-green-500 text-white text-xs px-4 py-1 mx-2 rounded-sm"
                      >
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between md:w-1/2 mx-auto">
          <Link
            to={`/event/bookingsteps/${EventId}/${AddressId}`}
            className="py-2 px-5 bg-gradient-to-r from-pink-500 to-red-500 text-white rounded shadow-lg hover:scale-105 transition-all"
          >
            Previous
          </Link>
          {selectedTicketId === null ? (
            <>
              <button className="py-2 px-5 bg-black bg-opacity-85 cursor-not-allowed text-white rounded transition-all">
                Next
              </button>
              <Tooltip id="nextButtonTooltip" place="left" effect="solid">
                Please select a Ticket
              </Tooltip>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <button
                type="submit"
                className="py-2 px-5 bg-gradient-to-r from-pink-500 to-red-500 text-white rounded shadow-lg hover:scale-105 transition-all"
              >
                Next
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingSteps3;
