/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { FaEye } from "react-icons/fa6";
import { Button } from "primereact/button";
import Config from "../../API/Config";
import {
  GetAllBookedOrderByEventId,
  getEventBookAddress,
  getEventBookDates,
} from "../../API/EventApi";
import usePageSEO from "../../hoosk/usepageSEO/Index";
import ScrollToTop from "../../ScrollToTop";
import {
  AssignSponsorTicket,
  getAllAssignSponsor,
  getAllSponsor,
} from "../../API/SponsorApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormLoader from "../../common/FormLoader";

const validationSchema = Yup.object().shape({
  SponsorId: Yup.string().required("Sponsor is required"),
  AddressId: Yup.string().required("Event Address is required"),
  DateId: Yup.string().required("Event Date is required"),
  Qty: Yup.string().required("Ticket Quantity is required"),
});

const SponsorAssignView = () => {
  const getVendorId = Config.getId();
  // ================ Get data by Id============
  const { Id } = useParams();
  const [EventAddressData, setEventAddressData] = useState([]);
  const [EventDateData, setEventDateData] = useState([]);
  const [EventData, setEventData] = useState();
  const [filterdata, setfilterdata] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [AssignData, setAssignData] = useState();

  const [Spon, setSpon] = useState([]); // Loading state
  const [search, setsearch] = useState("");

  const fetchData = async () => {
    try {
      if (Id) {
        const UserData = await GetAllBookedOrderByEventId(Id);
        setEventData(UserData);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  const fetchSponserData = async () => {
    try {
      const UserData = await getAllSponsor();
      setSpon(UserData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  const fetchEventAddressData = async () => {
    try {
      const result = await getEventBookAddress(Id);
      setEventAddressData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  const fetchEventDateData = async (addressId) => {
    if (addressId) {
      try {
        const result = await getEventBookDates(addressId);
        setEventDateData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    }
  };

  const fetchAssignData = async () => {
    try {
      if (Id) {
        const UserData = await getAllAssignSponsor(Id);
        setAssignData(UserData);
        setfilterdata(UserData);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData();
    fetchSponserData();
    fetchEventAddressData();
    fetchEventDateData();
    fetchAssignData();
  }, [Id]);
  usePageSEO(
    "Love My Show | Booking View", // title
    "Love My Show | Booking View", // description
    ["Love My Show | Booking View"] // keywords
  );
  const navigate = useNavigate();
  const actionTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon={<FaEye />}
          className="border border-blue-600 text-blue-600 mr-2 rounded-full py-2.5"
          onClick={() => {
            navigate(`/event/bookings/${rowData.Id}/${rowData.EventId}`);
          }}
        />
      </div>
    );
  };
  useEffect(() => {
    const mySearch = AssignData?.filter(
      (item) => item.Name && item.Name.toLowerCase().match(search.toLowerCase())
    );
    setfilterdata(mySearch);
  }, [search]);

  const [isFormLoading, setIsFormLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      VendorId: getVendorId,
      EventId: Id,
      SponsorId: "",
      AddressId: "",
      DateId: "",
      Qty: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const result = await AssignSponsorTicket(values);
        if (result.status === true) {
          actions.resetForm();
          fetchAssignData();
        }
      } catch (error) {
        console.error("Error updating slider:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white py-10">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <ScrollToTop />
      <div className="container mx-auto p-3">
        <div className="w-full border border-black dark:border-white pb-3.5">
          <div className="flex items-center  bg-black py-2 mb-2">
            <Link to={"/your-listing"}>
              <button
                type="button"
                className="flex text-white bg-green-500 dark:border-white dark:border px-6 py-1 my-1 ml-2 rounded-full"
              >
                Back
              </button>
            </Link>
            <h1 className="text-lg ml-auto text-sm pt-0 text-white pr-3">
              Booking Detail - {EventData?.EventName}
            </h1>
          </div>

          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#DC3558"} loading={loading} size={40} />
            </div>
          ) : (
            <>
              <div className="mt-2 mx-3">
                <div className="w-full ">
                  <h1 className="text-lg text-center p-2 mt-4 text-white font-bold bg-themecolor1">
                    Assign Ticket
                  </h1>
                </div>
                <div className="grid grid-cols-1 gap-5">
                  <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="container mx-auto border my-2 p-4">
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
                          <div>
                            <h1 className="mb-2 text-sm">
                              Select Sponsor
                              <span className="text-red-600">*</span>
                            </h1>

                            <select
                              name="SponsorId"
                              value={formik.values.SponsorId}
                              onChange={formik.handleChange}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white form-select`}
                            >
                              <option value="">Select Sponsor</option>
                              {Spon?.map((val, index) => {
                                return (
                                  <option value={val.Id} key={index}>
                                    {val.Name}
                                  </option>
                                );
                              })}
                            </select>
                            {formik.touched.SponsorId &&
                            formik.errors.SponsorId ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.SponsorId}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Select Event Address
                              <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="AddressId"
                              value={formik.values.AddressId}
                              onChange={(e) => {
                                formik.handleChange(e);
                                fetchEventDateData(e.target.value); // Fetch dates based on selected AddressId
                              }}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full dark:bg-boxdark-2 dark:text-white form-select`}
                            >
                              <option value="">Select Event Address</option>
                              {EventAddressData?.Addresses?.length > 0 ? (
                                EventAddressData.Addresses.map((val, index) => (
                                  <option value={val.Id} key={index}>
                                    {val.CityTitle}
                                  </option>
                                ))
                              ) : (
                                <option value="" disabled>
                                  No addresses available
                                </option>
                              )}
                            </select>
                            {formik.touched.AddressId &&
                            formik.errors.AddressId ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.AddressId}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Select Event Date
                              <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="DateId"
                              value={formik.values.DateId}
                              onChange={formik.handleChange}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white form-select`}
                            >
                              <option value="">Select Event Date</option>
                              {EventDateData?.Dates?.map((val, index) => {
                                return (
                                  <option value={val.Id} key={index}>
                                    {val.EventDate}
                                  </option>
                                );
                              })}
                            </select>
                            {formik.touched.DateId && formik.errors.DateId ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.DateId}
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Ticket Quantity
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              name="Qty"
                              value={formik.values.Qty}
                              onChange={formik.handleChange}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Sponsor Ticket Quantity"
                            />
                            {formik.touched.Qty && formik.errors.Qty ? (
                              <div className="text-red-500 text-xs mt-2">
                                {formik.errors.Qty}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <p className="mx-4 font-normal text-sm">
                        <b>Note:</b> Please assign the sponsor ticket. Once
                        assigned, it cannot be edited or deleted.
                      </p>

                      <div className="flex items-center ">
                        <button
                          type="submit"
                          className="bg-[#EC5E71] block md:my-3 placeholder:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto"
                        >
                          Assign
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="w-full ">
                <h1 className="text-lg text-center p-2 mt-4 text-white font-bold mx-3 bg-themecolor1">
                  Assigned Ticket
                </h1>
              </div>
              <div class="relative overflow-x-auto shadow-lg  mx-3  ">
                {AssignData && AssignData.length > 0 ? (
                  <DataTable
                    value={filterdata}
                    tableStyle={{
                      minWidth: "50rem",
                      border: "1px solid #e0e0e0",
                    }}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    emptyMessage="No Data found"
                    globalFilter={search}
                    header={
                      <div className="flex justify-between pb-5 p-ai-center">
                        <span className="p-input-icon-left">
                          <i className="pi pi-search" />
                          <InputText
                            type="text"
                            className="text-start me-auto text-sm border-2 py-2 mt-2 pl-2 md:pr-20 pr-5"
                            onInput={(e) => setsearch(e.target.value)}
                            placeholder="Search"
                          />
                        </span>
                      </div>
                    }
                  >
                    <Column
                      field="Id"
                      header="#"
                      sortable
                      className="border border-stroke"
                    />
                    <Column
                      field="Sponsor.Name"
                      header="Sponsor Name"
                      sortable
                      className="border border-stroke"
                    />
                    <Column
                      field="Sponsor.Phone"
                      header="Sponsor Phone"
                      className="border border-stroke"
                    />
                    <Column
                      field="Event.EventName"
                      header="Event Name"
                      className="border border-stroke"
                    />
                    <Column
                      field="Dates.EventDate"
                      header="Event Date"
                      className="border border-stroke"
                    />
                    <Column
                      field="Qty"
                      header="Assign Ticket Quantity"
                      className="border border-stroke"
                    />

                    <Column
                      header="Action"
                      className="border border-stroke"
                      body={actionTemplate}
                    />
                  </DataTable>
                ) : (
                  <div className="mx-3 my-3 py-16 bg-slate-300 font-bold text-2xl text-bodydark2 text-center">
                    No Booking Found
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SponsorAssignView;
