/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClipLoader from "react-spinners/BounceLoader";
import { format } from "date-fns";
import { GetAllBookedOrderById, GetTicketDownloadById } from "../API/EventApi";
import ScrollToTop from "../ScrollToTop";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { BsFillPrinterFill } from "react-icons/bs";
import FormLoader from "../common/FormLoader";

const ViewAllBookingInner = () => {
  usePageSEO(
    "Love My Show | View All Booking", // title
    "Love My Show | View All Booking", // description
    ["Love My Show | View All Booking "] // keywords
  );
  // ================ Get data by Id============
  const { Id } = useParams();
  const navigate = useNavigate();
  const [EventData, setEventData] = useState();
  const [loading, setLoading] = useState(true); // Loading state
  const fetchData = async () => {
    try {
      if (Id) {
        const UserData = await GetAllBookedOrderById(Id);
        setEventData(UserData);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchData();
  }, [Id]);
  const handleGoBack = () => {
    navigate(`/event/all-bookings`);
  };
  const statusText = EventData?.PaymentStatus === "1" ? "Success" : "Failed";
  const statusColor =
    EventData?.PaymentStatus === "1"
      ? "bg-green-600 text-white py-1 px-2 text-sm"
      : "bg-red-600 text-white py-1 px-2 text-sm";

  const [isFormLoading, setIsFormLoading] = useState(false);
  const HandlePrint = async (Id) => {
    setIsFormLoading(true);
    try {
      const result = await GetTicketDownloadById(Id);
      if (result) {
        const pdfUrl = result.pdfUrl;
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF generation failed:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  return (
    <div className=" dark:bg-boxdark-2 dark:text-white py-10">
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <ScrollToTop />
      <div className="container mx-auto">
        <div className="mx-4">
          <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
            {loading ? (
              <div className="flex justify-center items-center my-40">
                <ClipLoader color={"#DC3558"} loading={loading} size={40} />
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5.5 p-3.5 ">
                  {/*== =========Name===========*/}
                  <div>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>User Name:</b> {EventData?.UserName}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>User Email:</b> {EventData?.UserEmail}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>User Phone:</b> {EventData?.UserPhone}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Event Name:</b> {EventData?.EventName}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Event Email:</b> {EventData?.EventEmail}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Event Organizer:</b> {EventData?.EventOrganizer}
                    </label>

                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Event Start Date Time:</b>{" "}
                      {format(
                        new Date(EventData?.EventStartDateTime),
                        "dd/MM/yyyy"
                      )}
                    </label>

                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Age Restriction:</b> {EventData?.AgeRestriction}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Healthy Safety Precautions:</b>{" "}
                      {EventData?.HealthySafetyPrecautions}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>City:</b> {EventData?.City}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Country:</b> {EventData?.Country}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Language:</b> {EventData?.Language}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Location:</b> {EventData?.Location}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Event Start Time:</b> {EventData?.EventStartTime}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Event End Time:</b> {EventData?.EventEndTime}
                    </label>
                  </div>
                  <div className="relative">
                    <div className="absolute right-0 text-center">
                      <b>Ticket QR</b>
                      <img
                        src={EventData?.QRCODE}
                        className="w-40 my-3"
                        alt=""
                      />
                      <button
                        className="ml-auto bg-green-500 text-white p-2 rounded-full"
                        onClick={() => {
                          HandlePrint(EventData?.Id);
                        }}
                      >
                        <BsFillPrinterFill className="text-2xl text-white" />
                      </button>
                    </div>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Type:</b> {EventData?.Type}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Duration:</b> {EventData?.Duration}
                    </label>

                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Ticket Name:</b> {EventData?.TicketName}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>GST Percentage:</b> {EventData?.gstPercentage}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Price:</b> {EventData?.Price}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Quantity:</b> {EventData?.Qty}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Sub Total:</b> {EventData?.SubTotal}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>GST Amount:</b> {EventData?.gstAmount}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Charge:</b> {EventData?.Charge}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Coupon Code:</b> {EventData?.CouponCode}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Coupon Amount:</b> {EventData?.CouponAmount}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Total:</b> {EventData?.Total}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Payment Method:</b> {EventData?.PaymentMethod}
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Payment Status:</b>{" "}
                      <span className={statusColor}>{statusText}</span>
                    </label>
                    <label className="mb-3 text-sm block text-black dark:text-white">
                      <b>Expiry Date Time:</b>{" "}
                      {format(
                        new Date(EventData?.ExpiryDateTime),
                        "dd/MM/yyyy"
                      )}
                    </label>
                  </div>
                </div>
                <div class="relative overflow-x-auto shadow-md   bg-white dark:bg-boxdark-2 py-2 pt-1 border-t p-3.5">
                  <div className="col-span-2">
                    <div className="flex px-5.5 items-center  gap-5.5 py-3.5   col-span-1">
                      <button
                        className="flex justify-center rounded border border-stroke py-2 px-6 font-medium text-white bg-themecolor1 hover:shadow-1 dark:border-strokedark dark:text-white"
                        onClick={handleGoBack}
                        type="button"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAllBookingInner;
