/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import ContactusImg from "../../Images/contact.jpg";
import Breadcrumb from "../Breadcumb/Breadcumb";
import { FaEnvelope, FaMap, FaPhone } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import { ContactData } from "../API/ContactApi";
import usePageSEO from "../hoosk/usepageSEO/Index";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import PhoneInput from "react-phone-input-2";

const validationSchema = Yup.object().shape({
  Name: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "Name cannot contain numbers")
    .required("Name is required"),

  Email: Yup.string().email("Invalid email").required("Email is required"),

  Phone: Yup.string()
    .matches(/^[0-9]+$/, "Invalid mobile number")
    .required("Number is required"),

  Message: Yup.string().required("Message is required"),
});
const Contact = () => {
  usePageSEO(
    "Love My Show | Contact Us", // title
    "Love My Show | Contact Us", // description
    ["Love My Show | Contact Us"] // keywords
  );

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      Name: "",
      Email: "",
      Phone: "",
      Message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await ContactData(values);
        if (response.status === true) {
          actions.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const breadcrumbItems = [
    {
      text: "Contact Us",
    },
  ];

  const backgroundImage = ContactusImg;
  return (
    <div className="  dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <Breadcrumb items={breadcrumbItems} backgroundImage={backgroundImage} />
      <div className="container mx-auto py-5 ">
        <div className="bg-white border dark:border-gray-400 rounded-md p-2 lg:p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white mt-5 mx-4">
          <div className="grid grid-cols-1 lg:grid-cols-3   bg-white   dark:bg-boxdark-2   rounded-md lg:gap-5">
            {/*=================== Form Part =======================*/}
            <div className="col-span-2 mx-1 lg:mx-0  ">
              <h1 className="text-3xl font-bold  mb-5">Get in touch</h1>

              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-5">
                  <div className="">
                    <h1 className="mb-2 text-sm ">
                      First Name <span className="text-red-700 mr-2">*</span>
                    </h1>
                    <input
                      type="text"
                      name="Name"
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                      placeholder="First Name"
                      value={formik.values.Name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.Name && formik.errors.Name ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Name}
                      </div>
                    ) : null}
                  </div>

                  <div className="">
                    <h1 className="mb-2 text-sm">
                      Email Address <span className="text-red-700">*</span>
                    </h1>
                    <input
                      type="email"
                      name="Email"
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                      placeholder="Enter Your Email Address"
                      value={formik.values.Email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.Email && formik.errors.Email ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Email}
                      </div>
                    ) : null}
                  </div>
                  <div className="">
                    <h1 className="mb-2 text-sm mt-3">
                      Phone No <span className="text-red-700 ">*</span>
                    </h1>
                    <PhoneInput
                      country={"in"}
                      enableSearch={true}
                      value={formik.values.Phone}
                      onChange={(phone) => formik.setFieldValue("Phone", phone)}
                      name="Phone"
                      className="border-0 w-full text-black "
                    />

                    {formik.touched.Phone && formik.errors.Phone ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Phone}
                      </div>
                    ) : null}
                  </div>

                  <div className="">
                    <h1 className="mb-2 text-sm mt-3">
                      Message <span className="text-red-700 ">*</span>
                    </h1>
                    <textarea
                      rows="3"
                      name="Message"
                      value={formik.values.Message}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                    ></textarea>
                    {formik.touched.Message && formik.errors.Message ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik.errors.Message}
                      </div>
                    ) : null}
                  </div>
                </div>

                <button
                  className="bg-themecolor1 text-white mt-5 py-2 px-4 rounded-md"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>

            {/*===================== Image Part ====================*/}
            <div className="relative mt-5 lg:mt-0">
              <div className="grid grid-cols-5 gap-4 shadow-md rounded-md my-4 h-28  border dark:border-zinc-600 foldsmall:gap-2 p-2">
                <div className="col-span-1 my-auto ">
                  <h1 className="text-themecolor1 border border-themecolor1 rounded-full text-2xl h-12  w-12 my-auto p-3 mx-auto">
                    <FaMap className="block m-auto  mx-auto" />
                  </h1>
                </div>
                <div className="col-span-4 my-auto">
                  <p className="break-all text-sm lg:text-base text-justify">
                    Mondeal Heights, Near Novotel Hotel, SG Highway, Ahmedabad,
                    Gujarat, 380015.
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-4 shadow-md rounded-md my-4 h-28  border dark:border-zinc-600 foldsmall:gap-2 p-2">
                <div className="col-span-1 my-auto ">
                  <h1 className="text-themecolor1 border border-themecolor1 rounded-full text-2xl h-12  w-12 my-auto p-3 mx-auto">
                    <FaPhone className="block m-auto  mx-auto" />
                  </h1>
                </div>
                <div className="col-span-4 my-auto text-sm lg:text-base">
                  <p className="break-all">+91 1321321320</p>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-4 shadow-md rounded-md my-4 h-28  border dark:border-zinc-600 foldsmall:gap-2 p-2">
                <div className="col-span-1 my-auto ">
                  <h1 className="text-themecolor1 border border-themecolor1 rounded-full text-2xl h-12  w-12 my-auto p-3 mx-auto">
                    <FaEnvelope className="block m-auto  mx-auto" />
                  </h1>
                </div>
                <div className="col-span-4 my-auto text-sm lg:text-base">
                  <p className="break-all">
                    <a href="mailto:lovemyshoww@gmail.com">
                      lovemyshoww@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white my-3 p-3 border-t pt-4">
            <div className="w-full">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0073671372475!2d72.50437987616495!3d23.023501716292017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b3af448e1bf%3A0x817cc5cebb22cab1!2sMondeal%20Heights!5e0!3m2!1sen!2sin!4v1719990553723!5m2!1sen!2sin"
                width="100%"
                height="350"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
