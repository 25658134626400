/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  FaDollarSign,
  FaFacebook,
  FaInstagram,
  FaRupeeSign,
} from "react-icons/fa";
import Slider from "react-slick";
import { FcCalendar } from "react-icons/fc";
import {
  FaCirclePlay,
  FaLinkedin,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import * as Yup from "yup";

import { IoIosArrowForward } from "react-icons/io";

import { Link } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { getAllCategory } from "../API/CategoryApi";
import { NewslatterData } from "../API/ContactApi";
import { useFormik } from "formik";
import { getAllArtist } from "../API/ArtistApi";
import { getAllSlider } from "../API/SliderApi";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getHomeEventByCityId } from "../API/EventApi";
import Config from "../API/Config";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import NoDataFound from "../common/NodataFound";
import ScrollToTop from "../ScrollToTop";
import { format } from "date-fns";

const NewslatterDataValidation = Yup.object().shape({
  Email: Yup.string().email("Invalid email").required("Email is Required"),
});

const Home = () => {
  usePageSEO(
    "Love My Show | Home", // title
    "Love My Show | Home", // description
    ["Love My Show | Home "] // keywords
  );
  const bannerS = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "185px",
    slidesToShow: 1,
    arrows: false, // Add this line to disable arrows
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

          centerPadding: "0px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  const musicSliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1044,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 281,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const CategorySettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const artist = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 6,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   responsive: [
  //     {
  //       breakpoint: 1044,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //         infinite: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //         initialSlide: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 375,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 281,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  const CityId = Config.getCityIdData();
  const getCountrySlug = Config.getCountrySlug();
  const [loading, setLoading] = useState(true); // Loading state
  // ==================Slider Data===============
  const [SliderData, setSliderData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllSlider();
        setSliderData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  // ==================Category Data===============
  const [CategoryData, setCategoryData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllCategory();
        setCategoryData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  // ==================Artist Data===============
  const [ArtistData, setArtistData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllArtist();
        setArtistData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  // ==================CategoryEvent Data===============
  const [CategoryEventData, setCategoryEventData] = useState(null);

  useEffect(() => {
    const fetchCategoryEventData = async () => {
      try {
        const result = await getHomeEventByCityId(CityId);
        setCategoryEventData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchCategoryEventData();
  }, [CityId]);

  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------NewslatterData-----------------------
  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema: NewslatterDataValidation,
    onSubmit: async (values) => {
      setIsFormLoading(true);
      try {
        await NewslatterData(values);
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
      formik.resetForm();
    },
  });

  return (
    <div className="dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      {/*==================================Main slider ================================*/}
      <div className="Mainslider outline-none bg-gray-50 dark:bg-boxdark-2 dark:text-white ">
        <Slider {...bannerS} className="main-banner">
          {SliderData?.map((val, index) => {
            return (
              <div className="outline-none w-full" key={index}>
                <img src={val.Image} alt="" className="w-full lg:px-2 " />
              </div>
            );
          })}
        </Slider>
      </div>
      {/* ------------------category------------------*/}
      <section className="Magazine md:py-8 md:pt-6 py-4">
        <div className="container mx-auto px-5">
          <div className="flex items-center mx-4">
            <CiLocationOn className="border text-5xl p-2 rounded-xl" />
            <div>
              <h1 className="font-bold lg:text-2xl text-xl px-3 my-1 ml-1">
                Find New Experience
              </h1>
              <p className="my-4 px-3 -mt-1 ml-1 text-sm">
                Engage, Investigate, Draft a Plan
              </p>
            </div>
          </div>
          <div className="md:m-4 mt-3">
            {CategoryData?.length > 6 ? (
              <Slider {...CategorySettings}>
                {CategoryData?.map((val, index) => (
                  <div key={index} className="p-2">
                    <Link
                      to={`/event/${val.Id}/${val.Slug}`}
                      className="cursor-pointer"
                    >
                      <img
                        src={val.Image}
                        alt=""
                        className="rounded-xl h-auto w-full block m-auto"
                      />
                    </Link>
                  </div>
                ))}
              </Slider>
            ) : (
              <div className="grid grid-cols-2 lg:grid-cols-6 md:grid-cols-3 lg:gap-7 gap-4">
                {CategoryData?.map((val, index) => (
                  <div key={index}>
                    <Link
                      to={`/event/${val.Id}/${val.Slug}`}
                      className="cursor-pointer"
                    >
                      <img
                        src={val.Image}
                        alt=""
                        className="rounded-xl h-auto w-full block m-auto"
                      />
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>

      <div>
        {/*========================All categories Event======================*/}
        {loading ? (
          <div className="flex justify-center items-center my-40">
            <ClipLoader color={"#c82f32"} loading={loading} size={30} />
          </div>
        ) : (
          <div>
            {CategoryEventData?.map((category, categoryIndex) => {
              const isOdd = categoryIndex % 2 !== 0;
              return (
                <section
                  key={category.CategoryId}
                  className={`md:py-8 py-5 ${
                    isOdd ? "" : "bg-slate-200 dark:text-black"
                  }`}
                >
                  <div className="container mx-auto px-5">
                    <div className="flex px-2 items-center my-3">
                      <h1 className="md:text-2xl text-xl font-semibold maintitle">
                        {category.CategoryTitle}
                      </h1>
                      <Link
                        to={`/event/${category.CategoryId}/${category.CategorySlug}`}
                        className="text-sm cursor-pointer text-white dark:border-themecolor1 rounded font-semibold flex items-center ml-auto py-2 px-3 bg-gradient-to-r from-pink-500 to-red-500 shadow-lg transition-all"
                      >
                        See All
                        <IoIosArrowForward />
                      </Link>
                    </div>

                    {category.Events.length === 0 ? (
                      <div
                        className={`mx-auto bg-gray-200 font-bold text-2xl text-bodydark2 text-center ${
                          isOdd
                            ? "bg-white"
                            : "border dark:border-zinc-600 shadow-lg"
                        }`}
                      >
                        <NoDataFound />
                      </div>
                    ) : (
                      <>
                        <div className="hidden md:block">
                          {category.Events.length <= 4 ? (
                            <div className="grid lg:grid-cols-4 xl:grid-cols-5 md:grid-cols-3 grid-cols-2 foldsmall:grid-cols-1 gap-2">
                              {category.Events.slice(0, 10).map(
                                (event, eventIndex) => (
                                  <div key={eventIndex}>
                                    <div
                                      className={`overflow-hidden mx-2 mb-2 bg-white dark:bg-boxdark-2 dark:border dark:border-zinc-600 shadow-lg rounded-md ${
                                        isOdd
                                          ? "bg-white"
                                          : "border dark:border-zinc-600 shadow-lg"
                                      }`}
                                    >
                                      <Link
                                        to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                      >
                                        <img
                                          className="xl:h-80 lg:h-80 surface:h-72 rounded-md h-60 md:h-60 w-full rounded-b-none"
                                          src={event.Thumb}
                                          alt={event.EventName}
                                        />
                                        <div className="px-3 py-3">
                                          <h1 className="md:font-semibold pt-1 pb-1 mb-1 md:text-base text-gray-800 leading-8 text-sm line-clamp-1 dark:text-white">
                                            {event.EventName}
                                          </h1>
                                          <p className="text-sm line-clamp-1 mb-3 text-gray-500 dark:text-gray-400">
                                            {event.CategoryTitle}
                                          </p>

                                          <div className="mt-1">
                                            <div className="flex mb-3 md:items-center">
                                              <div className="flex md:text-sm text-xs font-semibold">
                                                <p className="text-sm mr-1">
                                                  <FcCalendar />
                                                </p>
                                                <h1 className="line-clamp-1 text-xs text-gray-400  font-normal">
                                                  {format(
                                                    new Date(
                                                      event.EventStartDateTime
                                                    ),
                                                    "dd/MM/yyyy"
                                                  )}
                                                </h1>
                                              </div>
                                              <div className="flex md:items-center md:text-sm text-xs font-semibold ml-auto">
                                                <p className="md:text-xl text-sm mx-1 me-2 text-red-600">
                                                  <FaCirclePlay />
                                                </p>
                                                <h1 className="line-clamp-1 text-xs font-medium dark:text-white">
                                                  {event.ArtistTitle}
                                                </h1>
                                              </div>
                                            </div>
                                            <div className="flex text-sm font-semibold pt-1 dark:text-white">
                                              <h1 className="my-auto flex items-center">
                                                {getCountrySlug === "India" ? (
                                                  <>
                                                    <span className="text-base">
                                                      <FaRupeeSign />
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="text-base">
                                                      <FaDollarSign />
                                                    </span>
                                                  </>
                                                )}{" "}
                                                {event.Price.TicketPrice}
                                              </h1>
                                              <button className="ml-auto bg-[#F84464] md:hover:bg-black rounded-full  px-3 py-1">
                                                <Link
                                                  to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                                  className="text-xs text-white font-medium"
                                                >
                                                  Buy Now
                                                </Link>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ) : category.Events.length > 4 ? (
                            <Slider {...musicSliderSettings}>
                              {category.Events.slice(0, 10).map(
                                (event, eventIndex) => (
                                  <div key={eventIndex}>
                                    <div
                                      className={`overflow-hidden mx-2 mb-3 bg-white dark:bg-boxdark-2 dark:border dark:border-zinc-600 shadow-lg rounded-md ${
                                        isOdd
                                          ? "bg-white"
                                          : "border dark:border-zinc-600 shadow-lg"
                                      }`}
                                    >
                                      <Link
                                        to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                      >
                                        <img
                                          className="xl:h-80 lg:h-80 surface:h-72 rounded-md h-60 md:h-60 w-full rounded-b-none"
                                          src={event.Thumb}
                                          alt={event.EventName}
                                        />
                                        <div className="px-3 py-3">
                                          <h1 className="md:font-semibold pt-1 pb-1 mb-1 md:text-base text-gray-800 leading-8 text-sm line-clamp-1 dark:text-white">
                                            {event.EventName}
                                          </h1>
                                          <p className="text-sm line-clamp-1 mb-3 text-gray-500 dark:text-gray-400">
                                            {event.CategoryTitle}
                                          </p>

                                          <div className="mt-1">
                                            <div className="flex mb-3 md:items-center">
                                              <div className="flex md:text-sm text-xs font-semibold">
                                                <p className="text-sm mr-1">
                                                  <FcCalendar />
                                                </p>
                                                <h1 className="line-clamp-1 text-xs text-gray-400  font-normal">
                                                  {format(
                                                    new Date(
                                                      event.EventStartDateTime
                                                    ),
                                                    "dd/MM/yyyy"
                                                  )}
                                                </h1>
                                              </div>
                                              <div className="flex md:items-center md:text-sm text-xs font-semibold ml-auto">
                                                <p className="md:text-xl text-sm mx-1 me-2 text-red-600">
                                                  <FaCirclePlay />
                                                </p>
                                                <h1 className="line-clamp-1 text-xs font-medium dark:text-white">
                                                  {event.ArtistTitle}
                                                </h1>
                                              </div>
                                            </div>
                                            <div className="flex text-sm font-semibold pt-1 dark:text-white">
                                              <h1 className="my-auto flex items-center">
                                                {getCountrySlug === "India" ? (
                                                  <>
                                                    <span className="text-base">
                                                      <FaRupeeSign />
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="text-base">
                                                      <FaDollarSign />
                                                    </span>
                                                  </>
                                                )}{" "}
                                                {event.Price.TicketPrice}
                                              </h1>
                                              <button className="ml-auto bg-[#F84464] md:hover:bg-black rounded-full  px-3 py-1">
                                                <Link
                                                  to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                                  className="text-xs text-white font-medium"
                                                >
                                                  BUY NOW
                                                </Link>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              )}
                            </Slider>
                          ) : (
                            <div
                              className={`mx-auto bg-gray-200 font-bold text-2xl text-bodydark2 text-center ${
                                !isOdd
                                  ? "bg-white"
                                  : "border dark:border-zinc-600 shadow-lg"
                              }`}
                            >
                              <NoDataFound />
                            </div>
                          )}
                        </div>
                        <div className="block md:hidden">
                          {category.Events.length <= 2 ? (
                            <div className="grid xs:grid-cols-1 grid-cols-2">
                              {category.Events.slice(0, 10).map(
                                (event, eventIndex) => (
                                  <div key={eventIndex}>
                                    <div
                                      className={`overflow-hidden mx-2 my-2 p-2 rounded-md ${
                                        isOdd
                                          ? "bg-white"
                                          : "border dark:border-zinc-600 shadow-lg"
                                      }`}
                                    >
                                      <Link
                                        to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                      >
                                        <img
                                          className="xl:h-80 lg:h-80 surface:h-72 rounded-md h-60 md:h-60 w-full rounded-b-none"
                                          src={event.Thumb}
                                          alt={event.EventName}
                                        />
                                        <div className="px-2 py-4">
                                          <h1 className="md:font-bold font-semibold md:text-base text-sm line-clamp-1">
                                            {event.EventName}
                                          </h1>
                                          <p className="text-black dark:text-white md:text-sm text-xs line-clamp-1">
                                            {event.CategoryTitle}
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="flex">
                                            <div className="flex md:text-sm text-xs font-semibold mb-2">
                                              <p className="md:text-xl text-sm mx-1">
                                                <FcCalendar />
                                              </p>
                                              <h1 className="line-clamp-1">
                                                {format(
                                                  new Date(
                                                    event.EventStartDateTime
                                                  ),
                                                  "dd/MM/yyyy hh:mm a"
                                                )}
                                              </h1>
                                            </div>
                                            <div className="flex md:text-sm text-xs font-semibold mb-2 ml-auto">
                                              <p className="md:text-xl text-sm mx-1">
                                                <FaCirclePlay />
                                              </p>
                                              <h1 className="line-clamp-1">
                                                {event.ArtistTitle}
                                              </h1>
                                            </div>
                                          </div>
                                          <div className="flex text-sm font-semibold bg-pink-100 rounded-md py-1 px-2 dark:text-black">
                                            <h1 className="my-auto flex items-center">
                                              {getCountrySlug === "India" ? (
                                                <>
                                                  <span className="text-base">
                                                    <FaRupeeSign />
                                                  </span>
                                                </>
                                              ) : (
                                                <>
                                                  <span className="text-base">
                                                    <FaDollarSign />
                                                  </span>
                                                </>
                                              )}{" "}
                                              {event.Price.TicketPrice}
                                            </h1>
                                            <button className="ml-auto">
                                              <Link
                                                to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                                className="md:text-base text-sm font-bold border-l-2 border-pink-300 pl-2"
                                              >
                                                BUY NOW
                                              </Link>
                                            </button>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ) : category.Events.length >= 2 ? (
                            <Slider {...musicSliderSettings}>
                              {category.Events.slice(0, 10).map(
                                (event, eventIndex) => (
                                  <div key={eventIndex}>
                                    <div
                                      className={`overflow-hidden mx-2 mb-2 bg-white dark:bg-boxdark-2 dark:border dark:border-zinc-600 shadow-lg rounded-md ${
                                        isOdd
                                          ? "bg-white"
                                          : "border dark:border-zinc-600 shadow-lg"
                                      }`}
                                    >
                                      <Link
                                        to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                      >
                                        <img
                                          className="xl:h-80 lg:h-80 surface:h-72 rounded-md h-60 md:h-60 w-full rounded-b-none"
                                          src={event.Thumb}
                                          alt={event.EventName}
                                        />
                                        <div className="px-3 py-3">
                                          <h1 className="md:font-bold font-semibold md:text-base text-sm line-clamp-1">
                                            {event.EventName}
                                          </h1>
                                          <p className="text-black dark:text-white md:text-sm text-xs line-clamp-1">
                                            {event.CategoryTitle}
                                          </p>

                                          <div className="mt-1">
                                            <div className="flex">
                                              <div className="flex md:text-sm text-xs font-semibold mb-2">
                                                <p className="md:text-xl text-sm mx-1">
                                                  <FcCalendar />
                                                </p>
                                                <h1 className="line-clamp-1">
                                                  {format(
                                                    new Date(
                                                      event.EventStartDateTime
                                                    ),
                                                    "dd/MM/yyyy hh:mm a"
                                                  )}
                                                </h1>
                                              </div>
                                              <div className="flex md:text-sm text-xs font-semibold mb-2 ml-auto">
                                                <p className="md:text-xl text-sm mx-1">
                                                  <FaCirclePlay />
                                                </p>
                                                <h1 className="line-clamp-1">
                                                  {event.ArtistTitle}
                                                </h1>
                                              </div>
                                            </div>
                                            <div className="flex text-sm font-semibold bg-pink-100 rounded-md py-1 px-2 dark:text-black">
                                              <h1 className="my-auto flex items-center">
                                                {getCountrySlug === "India" ? (
                                                  <>
                                                    <span className="text-base">
                                                      <FaRupeeSign />
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="text-base">
                                                      <FaDollarSign />
                                                    </span>
                                                  </>
                                                )}{" "}
                                                {event.Price.TicketPrice}
                                              </h1>
                                              <button className="ml-auto">
                                                <Link
                                                  to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                                  className="md:text-base text-sm font-bold border-l-2 border-pink-300 pl-2"
                                                >
                                                  BUY NOW
                                                </Link>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </div>
                                )
                              )}
                            </Slider>
                          ) : (
                            <div
                              className={`mx-auto bg-gray-200 font-bold text-2xl text-bodydark2 text-center ${
                                !isOdd
                                  ? "bg-white"
                                  : "border dark:border-zinc-600 shadow-lg"
                              }`}
                            >
                              <NoDataFound />
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </section>
              );
            })}
          </div>
        )}
      </div>

      <section className="py-10 bg-slate-200 dark:text-black dark:bg-gray-900">
        <div className="container mx-auto px-6">
          <div className="bg-white dark:bg-gray-800 p-8 border border-gray-300 dark:border-gray-700 rounded-xl shadow-lg">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
              Don't Miss Out on the Best Events in Your City!
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-400 mt-2 mb-6">
              Subscribe to our newsletter and be the first to know about
              exciting events and updates.
            </p>
            <form
              className="flex flex-col md:flex-row gap-4 mb-2"
              onSubmit={formik.handleSubmit}
            >
              <input
                type="text"
                value={formik.values.Email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="Email"
                placeholder="Enter your email address"
                className="border border-gray-300 dark:border-gray-600 rounded-lg p-3 w-full md:w-3/4 focus:ring-2 focus:ring-themecolor3 dark:focus:ring-themecolor3 transition"
              />
              <button
                type="submit"
                className="bg-themecolor3 text-white py-3 px-6 rounded-lg font-semibold w-full md:w-auto hover:bg-themecolor4 transition"
              >
                Subscribe Now
              </button>
            </form>
            {formik.touched.Email && formik.errors.Email && (
              <div className="text-red-500 text-sm mt-2">
                {formik.errors.Email}
              </div>
            )}
            <div className="mt-8 flex flex-col md:flex-row items-center justify-between">
              <p className="font-semibold text-gray-700 dark:text-white mb-4 md:mb-0">
                Follow us for the latest updates:
              </p>
              <div className="flex space-x-3">
                <Link
                  to={"https://www.facebook.com/lovemyshow.official/"}
                  target="_blank"
                  className="p-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-full shadow-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                >
                  <FaFacebook className="text-blue-600 dark:text-blue-500" />
                </Link>
                <Link
                  to={"https://x.com/Love_my_show"}
                  target="_blank"
                  className="p-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-full shadow-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                >
                  <FaXTwitter className="text-blue-400 dark:text-blue-300" />
                </Link>
                <Link
                  to={"https://www.instagram.com/love_myshow/"}
                  target="_blank"
                  className="p-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-full shadow-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                >
                  <FaInstagram className="text-pink-600 dark:text-pink-400" />
                </Link>
                <Link
                  to={"https://www.youtube.com/@LoveMyShow"}
                  target="_blank"
                  className="p-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-full shadow-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                >
                  <FaYoutube className="text-red-600 dark:text-red-400" />
                </Link>
                <Link
                  to={"https://www.linkedin.com/company/lovemyshow"}
                  target="_blank"
                  className="p-2 bg-gray-100 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-full shadow-md hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                >
                  <FaLinkedin className="text-blue-700 dark:text-blue-500" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
