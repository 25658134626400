import React, { useEffect, useState } from "react";
import { MdOutlinePayments } from "react-icons/md";
import { FaCalendar } from "react-icons/fa6";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getEventBookDates } from "../API/EventApi";
import { Tooltip } from "react-tooltip";
import { SiBookmyshow } from "react-icons/si";
import { FaAddressCard } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { CiStopwatch } from "react-icons/ci";
import ScrollToTop from "../ScrollToTop";

const BookingSteps2 = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const { EventId, AddressId } = useParams();
  const [EventAddressData, setEventAddressData] = useState([]);
  const [selectedDateId, setSelectedDateId] = useState(null);
  const navigate = useNavigate();
  usePageSEO(
    EventAddressData
      ? `Love My Show | ${EventAddressData?.CityTitle}`
      : "Love My Show | Booking - Event",
    EventAddressData
      ? `Love My Show | ${EventAddressData?.CityTitle}`
      : "Love My Show | Booking - Event",
    [
      EventAddressData
        ? `Love My Show | ${EventAddressData?.CityTitle}`
        : "Love My Show | Booking - Event",
    ] // No keywords provided in this example
  );

  useEffect(() => {
    const fetchEventAddressData = async () => {
      try {
        const result = await getEventBookDates(AddressId);
        setEventAddressData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchEventAddressData();
  }, [AddressId]);

  const handleDateSelect = (event) => {
    setSelectedDateId(event.target.value);
  };

  const RedirectTicket = async (e) => {
    try {
      navigate(`/event/bookingsteps/${EventId}/${AddressId}/${selectedDateId}`);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  return (
    <div className="py-5 container mx-auto ">
      <ScrollToTop />
      <div className="w-full py-4 px-4">
        <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-themecolor1"></div>
          <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gray-900 dark:bg-white transition-all duration-500"></div>
          <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor1 rounded-full place-items-center">
            <SiBookmyshow className="w-5 h-5" />
          </div>
          <div class="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-themecolor1 rounded-full place-items-center">
            <FaCalendar className="w-5 h-5" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center">
            <FaAddressCard className="w-5 h-5" />
          </div>
          <div className="relative grid w-10 h-10 font-bold text-gray-900 transition-all duration-300 bg-gray-300 rounded-full place-items-center">
            <MdOutlinePayments className="w-5 h-5" />
          </div>
        </div>
        <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-4 my-10">
          {loading ? (
            <div className="flex justify-center items-center my-40">
              <ClipLoader color={"#DC3558"} loading={loading} size={30} />
            </div>
          ) : (
            <>
              {EventAddressData?.Dates?.length > 0 ? (
                <>
                  <p className="text-center font-semibold dark:text-white text-xl mb-4">
                    Select Event Date
                  </p>
                  {EventAddressData.Dates.map((val, index) => {
                    const inputId = `add${index + 1}`;
                    return (
                      <label
                        htmlFor={inputId}
                        key={inputId}
                        className="mx-auto w-full px-0 py-5 bg-gray-100 shadow-lg relative cursor-pointer lg:w-[40%] transition-all duration-300 ease-in-out dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 rounded-lg"
                      >
                        <h1 className="font-semibold px-5 flex items-center text-xs mb-3">
                          <FaCalendar className="mr-2" />
                          {val.EventDate}
                        </h1>
                        <h1 className="font-semibold px-5 flex items-center text-xs">
                          <CiStopwatch className="mr-2 text-xl" />
                          {val.EventStartTime} - {val.EventEndTime}
                        </h1>
                        <input
                          type="radio"
                          name="show"
                          id={inputId}
                          value={val.Id}
                          onChange={handleDateSelect}
                          className="absolute top-1/2 right-5 transform -translate-x-1/2 -translate-y-1/2"
                        />
                      </label>
                    );
                  })}
                </>
              ) : (
                <p className="text-center font-bold text-xl mt-5 py-10 bg-red-200">
                  No dates found for this event
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex justify-between md:w-1/2 mx-auto">
          <div>
            <Link
              to={`/event/bookingsteps/${EventId}`}
              className="my-2 py-2 px-5 rounded flex mx-auto bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:scale-105 transition-all"
            >
              Previous
            </Link>
          </div>
          <div>
            {selectedDateId === null ? (
              <>
                <button
                  data-tooltip-id="nextButtonTooltip"
                  className="my-2 py-2 px-5 rounded mx-auto bg-black bg-opacity-85 cursor-not-allowed text-white hover:bg-opacity-95 transition-all"
                >
                  Next
                </button>
                <Tooltip id="nextButtonTooltip" place="left" effect="solid">
                  Please select Date
                </Tooltip>
              </>
            ) : (
              <button
                onClick={RedirectTicket}
                type="submit"
                className="my-2 py-2 px-5 rounded flex mx-auto bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:scale-105 transition-all"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingSteps2;
