import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getAllArtist } from "../API/ArtistApi";
import ClipLoader from "react-spinners/ClipLoader";
import ScrollToTop from "../ScrollToTop";
const Artist = () => {
  usePageSEO(
    "Love My Show | Artist", // title
    "Love My Show | Artist", // description
    ["Love My Show  | Artist"] // keywords
  );
  const usenavigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state
  // ==================Artist Data===============
  const [ArtistData, setArtistData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllArtist();
        setArtistData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);
  const navigateArtistBySlug = (Slug) => {
    usenavigate(`/artist/${Slug}`);
  };
  return (
    <div className="dark:bg-boxdark-2 dark:text-white ">
      <ScrollToTop />
      <div className="container mx-auto py-9">
        {loading ? (
          <div className="flex justify-center items-center my-40">
            <ClipLoader color={"#DC3558"} loading={loading} size={30} />
          </div>
        ) : (
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 p-5">
            {ArtistData?.map((val, index) => {
              return (
                <div className="border shadow-lg dark:border-zinc-600 rounded-md">
                  <div
                    className="relative md:mx-2 cursor-pointer"
                    key={index}
                    onClick={() => navigateArtistBySlug(val.Slug)}
                  >
                    <img
                      src={val.Image}
                      alt="Artist"
                      className="image w-full  rounded-full p-10"
                    />
                    <h1 className="text-center font-semibold pb-4">
                      {val.Title}
                    </h1>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Artist;
