/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaCalendar, FaEye, FaUser } from "react-icons/fa";
import { CgOrganisation } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import DashboardMenu from "./DashboardMenu";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { GetTicketDownloadById, getEventBookByUserId } from "../API/EventApi";
import Config from "../API/Config";
import ClipLoader from "react-spinners/ClipLoader";
import NoDataFound from "../common/NodataFound";
import { BsFillPrinterFill } from "react-icons/bs";
import { FaEnvelope, FaLocationDot, FaTicket } from "react-icons/fa6";
import ScrollToTop from "../ScrollToTop";
import FormLoader from "../common/FormLoader";

const Orderhistory = () => {
  usePageSEO(
    "Love My Show | Order History", // title
    "Love My Show | Order History", // description
    ["Love My Show | Order History "] // keywords
  );
  const UserId = Config.getId();

  const getStatusBadge = (status) => {
    switch (status) {
      case 0:
        return (
          <span className="bg-yellow-500 text-white text-xs py-1 px-3 rounded mr-2">
            Pending
          </span>
        );
      case 1:
        return (
          <span className="bg-green-500 text-white text-xs py-1 px-3 rounded mr-2">
            Confirmed
          </span>
        );
      case 2:
        return (
          <span className="bg-red-500 text-white text-xs py-1 px-3 rounded mr-2">
            Cancelled
          </span>
        );
      case 3:
        return (
          <span className="bg-blue-500 text-white text-xs py-1 px-3 rounded mr-2">
            Completed
          </span>
        );
      default:
        return null;
    }
  };
  // ==================EventBook Data===============
  const [EventBookData, setEventBookData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  const fetchData = async () => {
    try {
      const result = await getEventBookByUserId(UserId);
      setEventBookData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  // ------------------date format------------------
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  const formatEntDtDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // tickitpopup--------------
  const [ticketpopup, setticket] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const handleProfileClick = (Id) => {
    const event = EventBookData.find((event) => event.Id === Id);
    setSelectedEvent(event);
    setticket(true);
  };
  const [isFormLoading, setIsFormLoading] = useState(false);
  const HandlePrint = async (Id) => {
    setIsFormLoading(true);
    try {
      const result = await GetTicketDownloadById(Id);
      if (result) {
        const pdfUrl = result.pdfUrl;
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF generation failed:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  // -----------------------------pagination------------------------------
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = EventBookData?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(EventBookData?.length / itemsPerPage);

  const handlePrevious = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };

  const handleNext = () => {
    setCurrentPage(currentPage < totalPages ? currentPage + 1 : currentPage);
  };

  useEffect(() => {}, [currentPage]);

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          {/*================================ NavPills ======================*/}
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="">
                  <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                    <h1 className="text-lg  p-2 px-4  text-white font-bold bg-themecolor1">
                      Order History
                    </h1>

                    {loading ? (
                      <div className="flex justify-center items-center my-40">
                        <ClipLoader
                          color={"#DC3558"}
                          loading={loading}
                          size={30}
                        />
                      </div>
                    ) : (
                      <div className="grid  gap-4">
                        {currentItems?.length > 0 ? (
                          <>
                            {currentItems.map((val, index) => {
                              return (
                                <div
                                  key={index}
                                  className="shadow-lg p-3 border dark:border-zinc-700 mt-4 rounded-lg bg-white dark:bg-gray-800"
                                >
                                  <div className="flex flex-col md:flex-row items-start md:items-center border-b border-gray-300 dark:border-gray-600 pb-4">
                                    <div className="flex items-center">
                                      <span className="bg-teal-500 text-white px-3 py-1 rounded-full mr-3 text-lg">
                                        {index + 1}
                                      </span>
                                      <h2 className="text-xl font-semibold">
                                        {val.EventName}
                                      </h2>
                                    </div>
                                    <p className="mt-2 md:mt-0 text-sm text-gray-600 dark:text-gray-300 ml-auto">
                                      <b className="text-gray-800 dark:text-gray-200">
                                        Event Date:
                                      </b>{" "}
                                      {val.BookForDate}
                                    </p>
                                  </div>

                                  <div className="flex flex-col md:flex-row md:gap-6 mt-4">
                                    <div className="md:w-2/3 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
                                      <div className="flex items-center bg-gray-900 text-white p-3 rounded-t-lg">
                                        <h3 className="text-lg font-semibold">
                                          Event Details
                                        </h3>

                                        <BsFillPrinterFill
                                          className="text-4xl cursor-pointer bg-themecolor3 ml-auto text-white p-1.5 rounded-lg"
                                          onClick={() => {
                                            HandlePrint(val.Id);
                                          }}
                                        />
                                        <FaEye
                                          className="text-4xl cursor-pointer bg-themecolor3 ml-3 text-white p-1.5 rounded-lg"
                                          onClick={() =>
                                            handleProfileClick(val.Id)
                                          }
                                        />
                                      </div>
                                      <div className="flex items-center mt-3">
                                        <img
                                          src={val.Banner}
                                          alt="Event Thumbnail"
                                          className="w-full h-60 rounded-lg md:object-cover"
                                        />
                                      </div>
                                      <div className="ml-4">
                                        <p className="font-semibold text-lg">
                                          {val.EventName}
                                        </p>
                                        <p className="flex items-center text-sm my-1">
                                          <FaUser className="mr-2" />
                                          {val.UserName}
                                        </p>
                                        <p className="flex items-center text-sm my-1">
                                          <FaEnvelope className="mr-2" />
                                          {val.UserEmail}
                                        </p>
                                        <p className="flex items-center text-sm my-1">
                                          <CgOrganisation className="mr-2" />
                                          {val.EventOrganizer}
                                        </p>
                                        <p className="flex items-center text-sm my-1">
                                          <FaCalendar className="mr-2" />
                                          {formatDate(val.EventDate)} -{" "}
                                          {val.EventStartTime} to{" "}
                                          {val.EventEndTime}
                                        </p>
                                        <p className="flex items-center text-sm my-1">
                                          <FaLocationDot className="mr-2" />
                                          <b>Venue:</b> {val.Location},{" "}
                                          {val.City}, {val.Country}
                                        </p>
                                      </div>
                                      <div className="mt-4"></div>
                                      <div className="flex items-center justify-between mt-4">
                                        <p className="text-sm text-gray-600 dark:text-gray-300">
                                          {getStatusBadge(val.Status)} Enjoy the
                                          show
                                        </p>
                                      </div>
                                    </div>

                                    <div className="md:w-1/3 mt-4 md:mt-0 bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow-md">
                                      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200 mb-4 border-b border-black text-center">
                                        Payment Details
                                      </h3>

                                      <table className="w-full text-sm text-gray-600 dark:text-gray-300">
                                        <tbody>
                                          {[
                                            {
                                              label: "Ticket Name",
                                              value: val.TicketName,
                                            },
                                            {
                                              label: "Quantity",
                                              value: val.Qty,
                                            },
                                            {
                                              label: "Ticket Price",
                                              value: `₹${val.Price}`,
                                            },
                                            {
                                              label: "Platform Charge",
                                              value: `₹${val.Charge}`,
                                            },
                                            {
                                              label: "Coupon Code",
                                              value: val.CouponCode,
                                            },
                                            {
                                              label: "Coupon Amount",
                                              value: `₹${val.CouponAmount}`,
                                            },
                                            {
                                              label: "Sub Total",
                                              value: `₹${val.SubTotal}`,
                                            },
                                            {
                                              label: "Total",
                                              value: `₹${val.Total}`,
                                            },
                                            {
                                              label: "Booking Date",
                                              value: formatEntDtDate(val.EntDt),
                                            },
                                            {
                                              label: "Payment Method",
                                              value: val.PaymentMethod,
                                            },
                                            {
                                              label: "Payment Status",
                                              value:
                                                val.PaymentStatus === 1 ? (
                                                  <span className="bg-green-500 text-white text-xs p-0.5 px-3 rounded">
                                                    Success
                                                  </span>
                                                ) : (
                                                  <span className="bg-red-500 text-white text-xs p-0.5 px-3 rounded">
                                                    Failed
                                                  </span>
                                                ),
                                            },
                                            {
                                              label: "Language",
                                              value: val.Language,
                                            },
                                          ].map((item, i) => (
                                            <tr
                                              key={i}
                                              className="border-b dark:border-gray-600"
                                            >
                                              <th className="px-4 py-2 text-left">
                                                {item.label}
                                              </th>
                                              <td className="px-4 py-2 text-right">
                                                {item.value}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    )}

                    {EventBookData?.length > 5 ? (
                      <div className="flex justify-between mt-4">
                        <button
                          onClick={handlePrevious}
                          disabled={currentPage === 1}
                          className={`py-2 px-4 rounded ${
                            currentPage === 1
                              ? "bg-gray-300"
                              : "bg-themecolor1 text-white"
                          }`}
                        >
                          Previous
                        </button>
                        <span className="py-2 px-4">{`Page ${currentPage} of ${totalPages}`}</span>
                        <button
                          onClick={handleNext}
                          disabled={currentPage === totalPages}
                          className={`py-2 px-4 rounded ${
                            currentPage === totalPages
                              ? "bg-gray-300"
                              : "bg-themecolor1 text-white"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {ticketpopup && selectedEvent && (
        <div>
          <div className="px-5 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-96">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white dark:bg-boxdark-2 dark:text-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-2 border-b dark:border-zinc-600 border-solid border-blueGray-200 rounded-t bg-themecolor1">
                  <h3 className="text-xl font-semibold text-white pl-2">
                    Ticket
                  </h3>
                  <button
                    className="background-transparent text-white font-bold uppercase text-3xl outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setticket(false)}
                  >
                    <RxCross2 />
                  </button>
                </div>
                <div className="p-3 relative">
                  <div className="grid grid-cols-5">
                    <div className="col-span-1 my-auto">
                      <img
                        src={selectedEvent.Thumb}
                        alt=""
                        className="mx-auto w-full h-full"
                      />
                    </div>
                    <div className="col-span-4 ml-3">
                      <p className="font-semibold text-sm ">
                        {selectedEvent.EventName}
                      </p>
                      <p className=" flex items-center text-xs my-1">
                        <FaEnvelope className="mr-1.5" />
                        {selectedEvent.UserEmail}
                      </p>
                      <p className=" flex items-center text-xs my-1">
                        <FaUser className="mr-1.5" />
                        {selectedEvent.UserName}
                      </p>
                      <p className=" flex items-center text-xs my-1">
                        <CgOrganisation className="mr-1.5" />
                        {selectedEvent.EventOrganizer}
                      </p>

                      <p className=" flex items-center text-xs my-1">
                        <FaCalendar className="mr-1.5" />
                        <div>
                          <p>{formatDate(selectedEvent.EventDate)}</p>
                          <p>
                            {" "}
                            {selectedEvent.EventStartTime} -{" "}
                            {selectedEvent.EventEndTime}
                          </p>
                        </div>
                      </p>

                      <p className=" flex items-center text-xs my-1">
                        <FaLocationDot className="mr-1.5" />
                        <b>Venue: </b> {selectedEvent.Location},
                        {selectedEvent.City},{selectedEvent.Country}
                      </p>
                      <p className=" flex items-center text-xs my-1">
                        <FaTicket className="mr-1.5" />
                        <b>{selectedEvent.Qty}</b> Ticket
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
                <p className="border-t-2 border-gray-200 dark:border-white p-3 text-xs">
                  {getStatusBadge(selectedEvent.Status)}| Enjoy the show
                </p>
                <div className="border-t-2 border-gray-200 py-3  flex items-center px-4">
                  <img
                    src={selectedEvent.QRCODE}
                    alt=""
                    className="w-24 dark:bg-white"
                  />
                  <div className="mx-auto">
                    <p className="text-center text-sm text-themecolor1">
                      <b>BOOKING ID: </b>
                      {selectedEvent.Id}
                    </p>
                  </div>
                </div>
                <div className="border-t-2 border-gray-200 p-3 flex items-center">
                  <p className="text-center mx-auto text-xs">
                    Cancellation not available for this venue
                  </p>
                </div>
                <div className="border-t-2 border-gray-200 p-3 flex items-center font-bold md:px-4">
                  <p>Total Amount</p>
                  <p className="ml-auto text-green-700">
                    Rs. {selectedEvent.Total}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
      )}
    </div>
  );
};

export default Orderhistory;
