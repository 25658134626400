/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa6";
import DashboardMenu from "./DashboardMenu";
import Config from "../API/Config";
import {
  getAllLanguage,
  getAllCountry,
  getCityByCountryId,
} from "../API/State-City-Language-API";
import FormLoader from "../common/FormLoader";
import * as Yup from "yup";
import { AddEventAddress } from "../API/EventApi";
import { useNavigate, useParams } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { FaDollarSign, FaRupeeSign } from "react-icons/fa";
const validationSchema = Yup.object().shape({
  CityId: Yup.string().required("City is required"),
  CountryId: Yup.string().required("Country is required"),
  LanguageId: Yup.string().required("Language is required"),
  Duration: Yup.string().required("Duration is required"),
  Type: Yup.string().required("Type is required"),
  Location: Yup.string().required("Location is required"),
  Address: Yup.string().required("Address is required"),
  EventTicket: Yup.array().of(
    Yup.object().shape({
      EventDate: Yup.string().required("Event Date is required"),
      EventStartTime: Yup.string().required("Event Start Time is required"),
      EventEndTime: Yup.string().required("Event End Time is required"),
      Ticketdata: Yup.array().of(
        Yup.object().shape({
          tname: Yup.string().required("Ticket name is required"),
          tamt: Yup.number()
            .required("Ticket amount is required")
            .positive("Ticket amount must be positive")
            .min(1, "Ticket amount must be at least 1")
            .max(50000, "Ticket amount cannot exceed 50000"),
          tqty: Yup.number()
            .required("Ticket quantity is required")
            .integer("Ticket quantity must be an integer")
            .min(1, "Ticket quantity must be at least 1")
            .max(10000000, "Ticket quantity cannot exceed 10000000"),
        })
      ),
    })
  ),
});
const AddAddress = () => {
  usePageSEO(
    "Love My Show | Add Address", // title
    "Love My Show | Add Address", // description
    ["Love My Show | Add Address"] // keywords
  );
  const { Id } = useParams(); // Assuming this fetches the correct Id from URL params
  const CountryName = Config.getCountrySlug();
  const CountryId = Config.getCountryId();
  const InitialFormData = {
    EventId: Id,
    CityId: "",
    CountryId: CountryId,
    Country: CountryName,
    LanguageId: "",
    Duration: "",
    Type: "",
    Location: "",
    Address: "",
    EventTicket: [
      {
        EventDate: "",
        EventStartTime: "",
        EventEndTime: "",
        Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
      },
    ],
  };
  const [LanguageData, setLanguageData] = useState(null);
  const [CityData, setCityData] = useState([]);
  const [StateData, setStateData] = useState(null);
  const [formData, setFormData] = useState(InitialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isFormLoading, setIsFormLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (formData.CountryId) {
      fetchCities(formData.CountryId);
    }
  }, [formData.CountryId]);

  useEffect(() => {
    const fetchLanguageData = async () => {
      try {
        const result = await getAllLanguage();
        setLanguageData(result);
      } catch (error) {
        console.error("Error fetching language data:", error);
      }
    };

    const fetchStateData = async () => {
      try {
        const result = await getAllCountry();
        setStateData(result);
        setFormData((prevState) => ({
          ...prevState,
          CountryId: CountryId1, // Assuming result is an array of countries with 'id' property
          Country: CountryName,
        }));
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchLanguageData();
    fetchStateData();
  }, []);

  const CountryId1 = Config.getCountryId();
  const fetchCities = async (countryId) => {
    try {
      const response = await getCityByCountryId(CountryId1);
      setCityData(response);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleInputChange = async (e, ticketIndex, subTicketIndex) => {
    const { name, value } = e.target;

    if (name === "CountryId") {
      fetchCities(value);
    }

    setFormData((prevState) => ({
      ...prevState,
      EventTicket: prevState.EventTicket.map((ticket, i) => {
        if (ticketIndex !== i) return ticket;
        if (subTicketIndex !== undefined) {
          return {
            ...ticket,
            Ticketdata: ticket.Ticketdata.map((subTicket, j) => {
              if (j !== subTicketIndex) return subTicket;
              return { ...subTicket, [name]: value };
            }),
          };
        } else {
          return { ...ticket, [name]: value };
        }
      }),
      [name]: value,
    }));

    // Clear error message when user starts typing
    setFormErrors((prevErrors) => {
      if (prevErrors[name]) {
        return { ...prevErrors, [name]: "" }; // Clear the error message for this field
      }
      return prevErrors;
    });
  };

  const handleDeleteTicketData = (ticketIndex, subTicketIndexToDelete) => {
    setFormData((prevState) => ({
      ...prevState,
      EventTicket: prevState.EventTicket.map((ticket, i) => {
        if (ticketIndex !== i) return ticket;
        return {
          ...ticket,
          Ticketdata: ticket.Ticketdata.filter(
            (_, j) => j !== subTicketIndexToDelete
          ),
        };
      }),
    }));
  };
  const handleDeleteDateData = (ticketIndex) => {
    setFormData((prevState) => ({
      ...prevState,
      EventTicket: prevState.EventTicket.filter((_, i) => i !== ticketIndex),
    }));
  };

  const handleAddTicketData = (index) => {
    setFormData((prevState) => {
      const updatedEventTicket = prevState.EventTicket.map((ticket, i) => {
        if (i === index) {
          return {
            ...ticket,
            Ticketdata: [
              ...ticket.Ticketdata,
              { tname: "", tamt: "", tqty: "" },
            ],
          };
        }
        return ticket;
      });
      return { ...prevState, EventTicket: updatedEventTicket };
    });
  };
  const getMinDateTime = () => {
    const now = new Date();
    now.setDate(now.getDate() + 1); // Add 1 day
    const minDate = now.toISOString().split("T")[0]; // Get YYYY-MM-DD format
    return minDate;
  };

  const handleAddEventTicket = () => {
    setFormData((prevState) => ({
      ...prevState,
      EventTicket: [
        ...prevState.EventTicket,
        {
          EventDate: "",
          EventStartTime: "",
          EventEndTime: "",
          Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
        },
      ],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const data = await AddEventAddress(formData);
      if (data.status === true) {
        navigate("/your-listing");
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      } else {
        console.error("Submission Error:", error);
      }
    } finally {
      setIsFormLoading(false);
    }
  };
  const AddNewAddress = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const data = await AddEventAddress(formData);
      if (data.status === true) {
        // Clearing specific fields and setting others
        setFormData({
          ...InitialFormData,
          EventId: InitialFormData.EventId, // Setting EventId from InitialFormData
          Country: InitialFormData.Country, // Setting Country from InitialFormData
          CountryId: InitialFormData.CountryId, // Setting CountryId from InitialFormData
          CityId: "",
          LanguageId: "",
          Duration: "",
          Type: "",
          Location: "",
          Address: "",
          EventTicket: [
            {
              EventDate: "",
              EventStartTime: "",
              EventEndTime: "",
              Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
            },
          ],
        });
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setFormErrors(validationErrors);
      } else {
        console.error("Submission Error:", error);
      }
    } finally {
      setIsFormLoading(false);
    }
  };
  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2">
                    <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                      Add Address
                    </h1>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <h1 className="text-lg p-2 py-2 mt-4 text-white font-bold bg-black container mx-auto">
                      Event Details <span className="text-red-600">*</span>
                    </h1>
                    <div className="container mx-auto border my-2 p-4">
                      <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                        <div>
                          <h1 className="mb-2 text-sm">
                            Country <span className="text-red-600">*</span>
                          </h1>
                          <select
                            name="CountryId"
                            disabled
                            value={formData.CountryId}
                            onChange={(e) => handleInputChange(e)}
                            className="form-select border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                          >
                            {StateData?.map((val, index) => {
                              return (
                                <option value={val.Id} key={index}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {formErrors.CountryId && (
                            <p className="text-red-500 text-sm">
                              {formErrors.CountryId}
                            </p>
                          )}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            City <span className="text-red-600">*</span>
                          </h1>
                          <select
                            name="CityId"
                            value={formData.CityId}
                            onChange={(e) => handleInputChange(e)}
                            className="form-select border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                          >
                            <option value="">Select City</option>
                            {CityData?.map((val, index) => (
                              <option value={val.Id} key={index}>
                                {val.Title}
                              </option>
                            ))}
                          </select>
                          {formErrors.CityId && (
                            <p className="text-red-500 text-sm">
                              {formErrors.CityId}
                            </p>
                          )}
                        </div>

                        <div>
                          <h1 className="mb-2 text-sm">
                            Event Language{" "}
                            <span className="text-red-600">*</span>
                          </h1>
                          <select
                            name="LanguageId"
                            value={formData.LanguageId}
                            onChange={(e) => handleInputChange(e)}
                            className="form-select border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                          >
                            <option value="">Select Language</option>
                            {LanguageData?.map((val, index) => {
                              return (
                                <option value={val.Id} key={index}>
                                  {val.Title}
                                </option>
                              );
                            })}
                          </select>
                          {formErrors.LanguageId && (
                            <p className="text-red-500 text-sm">
                              {formErrors.LanguageId}
                            </p>
                          )}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            Event Duration{" "}
                            <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            name="Duration"
                            value={formData.Duration}
                            onChange={(e) => handleInputChange(e)}
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                            placeholder="Event Duration"
                          />
                          {formErrors.Duration && (
                            <p className="text-red-500 text-sm">
                              {formErrors.Duration}
                            </p>
                          )}
                        </div>
                        <div>
                          <h1 className="mb-2 text-sm">
                            Select Type of Event{" "}
                            <span className="text-red-600">*</span>
                          </h1>
                          <select
                            id="type"
                            name="Type"
                            value={formData.Type}
                            onChange={(e) => handleInputChange(e)}
                            className="form-select border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                          >
                            <option value="">Select Type of Event</option>
                            <option value="Indoor">Indoor</option>
                            <option value="Outdoor">Outdoor</option>
                          </select>
                          {formErrors.Type && (
                            <p className="text-red-500 text-sm">
                              {formErrors.Type}
                            </p>
                          )}
                        </div>

                        <div>
                          <h1 className="mb-2 text-sm">
                            Location <span className="text-red-600">*</span>
                          </h1>
                          <input
                            type="text"
                            value={formData.Location}
                            onChange={(e) => handleInputChange(e)}
                            name="Location"
                            className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                            placeholder="Location"
                          />
                          {formErrors.Location && (
                            <p className="text-red-500 text-sm">
                              {formErrors.Location}
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <h1 className="mb-2 text-sm mt-3">
                          Location Iframe{" "}
                          <span className="text-red-600">*</span>
                        </h1>
                        <textarea
                          name="Address"
                          value={formData.Address}
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Location Iframe"
                          className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                        ></textarea>
                        {formErrors.Address && (
                          <p className="text-red-500 text-sm">
                            {formErrors.Address}
                          </p>
                        )}
                      </div>
                    </div>
                    {/* EventTicket */}
                    {formData.EventTicket.map((ticket, index) => (
                      <div
                        key={index}
                        className="container mx-auto border p-4 my-6"
                      >
                        <div className="flex flex-col md:flex-row md:justify-between justify-start md:items-center items-start mb-4">
                          <p className="m-0 border border-themecolor1 py-1.5 rounded-3xl">
                            <span className="bg-themecolor1  text-white px-3.5 py-2 rounded-full mr-2">
                              {index + 1}
                            </span>
                            <span className="pr-3 text-xs">
                              Event Ticket {index + 1}
                            </span>
                          </p>

                          <div className="flex items-center md:mt-0 mt-2">
                            <button
                              type="button"
                              onClick={() => handleAddEventTicket()}
                              className="bg-green-500 py-2 px-5 flex items-center text-white"
                            >
                              Add Event Date
                            </button>
                            <button
                              type="button"
                              onClick={() => handleDeleteDateData(index)}
                              className="bg-red-500 text-white px-4 py-3 rounded-md ml-2"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </div>
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
                          <div>
                            <h1 className="mb-2 text-sm">
                              Event Date <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="date"
                              name="EventDate"
                              required
                              value={ticket.EventDate}
                              onChange={(e) => handleInputChange(e, index)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Event Date"
                              min={getMinDateTime()}
                            />
                            {formErrors[`EventTicket[${index}].EventDate`] && (
                              <p className="text-red-500 mt-1">
                                {formErrors[`EventTicket[${index}].EventDate`]}
                              </p>
                            )}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Event Start Time{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="time"
                              name="EventStartTime"
                              required
                              value={ticket.EventStartTime}
                              onChange={(e) => handleInputChange(e, index)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Event Date"
                            />
                            {formErrors[
                              `EventTicket[${index}].EventStartTime`
                            ] && (
                              <p className="text-red-500 mt-1">
                                {
                                  formErrors[
                                    `EventTicket[${index}].EventStartTime`
                                  ]
                                }
                              </p>
                            )}
                          </div>
                          <div>
                            <h1 className="mb-2 text-sm">
                              Event End Time{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="time"
                              required
                              name="EventEndTime"
                              value={ticket.EventEndTime}
                              onChange={(e) => handleInputChange(e, index)}
                              className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                              placeholder="Event Date"
                            />
                            {formErrors[
                              `EventTicket[${index}].EventEndTime`
                            ] && (
                              <p className="text-red-500 mt-1">
                                {
                                  formErrors[
                                    `EventTicket[${index}].EventEndTime`
                                  ]
                                }
                              </p>
                            )}
                          </div>
                        </div>
                        {ticket.Ticketdata.map((subTicket, subIndex) => (
                          <div
                            key={subIndex}
                            className="container mx-auto grid lg:grid-cols-10 md:grid-cols-7 grid-cols-1 gap-3 border dark:border-zinc-600 p-2 my-6 relative"
                          >
                            <div className="lg:col-span-3 md:col-span-2 mt-4">
                              <h1 className="mb-2 text-sm">
                                Ticket Name{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <input
                                type="text"
                                required
                                id={`tname${subIndex}`}
                                name="tname"
                                value={subTicket.tname}
                                onChange={(e) =>
                                  handleInputChange(e, index, subIndex)
                                }
                                className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                                placeholder="Ticket Name"
                              />
                              {formErrors[
                                `EventTicket[${index}].Ticketdata[${subIndex}].tname`
                              ] && (
                                <p className="text-red-500 mt-1">
                                  {
                                    formErrors[
                                      `EventTicket[${index}].Ticketdata[${subIndex}].tname`
                                    ]
                                  }
                                </p>
                              )}
                            </div>
                            <div className="lg:col-span-3 md:col-span-2 mt-2">
                              <h1 className="text-sm py-2 flex items-center">
                                Ticket Price{" "}
                                {CountryName === "India" ? (
                                  <>
                                    (
                                    <span className="text-sm">
                                      <FaRupeeSign />
                                    </span>
                                    )
                                  </>
                                ) : (
                                  <>
                                    (
                                    <span className="text-sm">
                                      <FaDollarSign />
                                    </span>
                                    )
                                  </>
                                )}
                                <span className="text-red-600">*</span>
                              </h1>
                              <input
                                type="number"
                                required
                                min="0"
                                id={`tamt${subIndex}`}
                                name="tamt"
                                value={subTicket.tamt}
                                onChange={(e) =>
                                  handleInputChange(e, index, subIndex)
                                }
                                className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                                placeholder="Ticket Price"
                              />
                              {formErrors[
                                `EventTicket[${index}].Ticketdata[${subIndex}].tamt`
                              ] && (
                                <p className="text-red-500 mt-1">
                                  {
                                    formErrors[
                                      `EventTicket[${index}].Ticketdata[${subIndex}].tamt`
                                    ]
                                  }
                                </p>
                              )}
                            </div>
                            <div className="lg:col-span-3 md:col-span-2 mt-2">
                              <h1 className="text-sm py-2">
                                Ticket Quantity{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <input
                                type="number"
                                required
                                min="0"
                                id={`tqty${subIndex}`}
                                name="tqty"
                                value={subTicket.tqty}
                                onChange={(e) =>
                                  handleInputChange(e, index, subIndex)
                                }
                                className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                                placeholder="Ticket Quantity"
                              />
                              {formErrors[
                                `EventTicket[${index}].Ticketdata[${subIndex}].tqty`
                              ] && (
                                <p className="text-red-500 mt-1">
                                  {
                                    formErrors[
                                      `EventTicket[${index}].Ticketdata[${subIndex}].tqty`
                                    ]
                                  }
                                </p>
                              )}
                            </div>
                            <div className="lg:col-span-1 md:col-span-1 mt-2">
                              <h1 className="text-sm py-2">Action</h1>
                              <button
                                type="button"
                                onClick={() =>
                                  handleDeleteTicketData(index, subIndex)
                                }
                                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
                              >
                                <FaTrash />
                              </button>
                            </div>
                            <h1 className="absolute bg-themecolor2 text-white px-2 -top-3">
                              {subIndex + 1}
                            </h1>
                          </div>
                        ))}
                        <div className="flex">
                          <button
                            type="button"
                            onClick={() => handleAddTicketData(index)}
                            className="bg-green-500 py-2 px-5 flex items-center text-white"
                          >
                            Add Ticket
                          </button>
                        </div>
                      </div>
                    ))}

                    <div className="flex flex-col md:flex-row md:justify-between justify-start md:items-center items-start">
                      <button
                        type="submit"
                        className="bg-[#EC5E71] block md:my-5 placeholder:my-2 text-white font-semibold px-12 p-2 rounded-md md:mr-auto foldsmall:mx-auto"
                      >
                        Submit
                      </button>
                      <button
                        type="button"
                        onClick={AddNewAddress}
                        className="bg-[#EC5E71] block md:my-5 my-2 text-white font-semibold px-12 p-2 rounded-md md:ml-auto foldsmall:mx-auto"
                      >
                        Save And Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddress;

// import React, { useEffect, useState } from "react";
// import { FaTrash } from "react-icons/fa6";
// import DashboardMenu from "./DashboardMenu";
// import Config from "../API/Config";
// import {
//   getAllLanguage,
//   getAllCountry,
//   getCityByCountryId,
// } from "../API/State-City-Language-API";
// import FormLoader from "../common/FormLoader";
// import * as Yup from "yup";
// import { AddEventAddress } from "../API/EventApi";
// import { useNavigate, useParams } from "react-router-dom";
// import ScrollToTop from "../ScrollToTop";
// import usePageSEO from "../hoosk/usepageSEO/Index";
// import { FaDollarSign, FaRupeeSign } from "react-icons/fa";
// const validationSchema = Yup.object().shape({
//   CityId: Yup.string().required("City is required"),
//   CountryId: Yup.string().required("Country is required"),
//   LanguageId: Yup.string().required("Language is required"),
//   Duration: Yup.string().required("Duration is required"),
//   Type: Yup.string().required("Type is required"),
//   Location: Yup.string().required("Location is required"),
//   Address: Yup.string().required("Address is required"),
//   EventTicket: Yup.array().of(
//     Yup.object().shape({
//       EventDate: Yup.string().required("Event Date is required"),
//       EventStartTime: Yup.string().required("Event Start Time is required"),
//       EventEndTime: Yup.string().required("Event End Time is required"),
//       Ticketdata: Yup.array().of(
//         Yup.object().shape({
//           tname: Yup.string().required("Ticket name is required"),
//           tamt: Yup.number()
//             .required("Ticket amount is required")
//             .positive("Ticket amount must be positive")
//             .min(1, "Ticket amount must be at least 1")
//             .max(50000, "Ticket amount cannot exceed 50000"),
//           tqty: Yup.number()
//             .required("Ticket quantity is required")
//             .integer("Ticket quantity must be an integer")
//             .min(1, "Ticket quantity must be at least 1")
//             .max(10000000, "Ticket quantity cannot exceed 10000000"),
//         })
//       ),
//     })
//   ),
// });

// const AddAddress = () => {
//   const { Id } = useParams(); // Assuming this fetches the correct Id from URL params
//   const CountryName = Config.getCountrySlug();
//   const CountryId = Config.getCountryId();
//   const InitialFormData = {
//     EventId: Id,
//     CityId: "",
//     CountryId: CountryId,
//     Country: CountryName,
//     LanguageId: "",
//     Duration: "",
//     Type: "",
//     Location: "",
//     Address: "",
//     EventTicket: [
//       {
//         EventDate: "",
//         EventStartTime: "",
//         EventEndTime: "",
//         Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
//       },
//     ],
//   };

//   const [formData, setFormData] = useState(InitialFormData);
//   const [formErrors, setFormErrors] = useState({});
//   const [isFormLoading, setIsFormLoading] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchLanguageData();
//     fetchStateData();
//   }, []);
//   const [LanguageData, setLanguageData] = useState(null);
//   const [CityData, setCityData] = useState([]);
//   const [StateData, setStateData] = useState(null);
//   useEffect(() => {
//     if (formData.CountryId) {
//       fetchCities(formData.CountryId);
//     }
//   }, [formData.CountryId]);

//   const fetchLanguageData = async () => {
//     try {
//       const result = await getAllLanguage();
//       setLanguageData(result);
//     } catch (error) {
//       console.error("Error fetching language data:", error);
//     }
//   };

//   const fetchStateData = async () => {
//     try {
//       const result = await getAllCountry();
//       setStateData(result);
//       setFormData((prevState) => ({
//         ...prevState,
//         CountryId: CountryId1, // Assuming result is an array of countries with 'id' property
//         Country: CountryName,
//       }));
//     } catch (error) {
//       console.error("Error fetching country data:", error);
//     }
//   };

//   const CountryId1 = Config.getCountryId();
//   const fetchCities = async (countryId) => {
//     try {
//       const response = await getCityByCountryId(CountryId1);
//       setCityData(response);
//     } catch (error) {
//       console.error("Error fetching cities:", error);
//     }
//   };

//   const handleInputChange = async (e, ticketIndex, subTicketIndex) => {
//     const { name, value } = e.target;

//     if (name === "CountryId") {
//       fetchCities(value);
//     }

//     // Update form data
//     setFormData((prevState) => ({
//       ...prevState,
//       EventTicket: prevState.EventTicket.map((ticket, i) => {
//         if (ticketIndex !== i) return ticket;
//         if (subTicketIndex !== undefined) {
//           return {
//             ...ticket,
//             Ticketdata: ticket.Ticketdata.map((subTicket, j) => {
//               if (j !== subTicketIndex) return subTicket;
//               return { ...subTicket, [name]: value };
//             }),
//           };
//         } else {
//           return { ...ticket, [name]: value };
//         }
//       }),
//       [name]: value,
//     }));

//     // Validate the specific field after change
//     try {
//       await validationSchema.validateAt(name, formData);
//       setFormErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: "",
//       }));
//     } catch (err) {
//       setFormErrors((prevErrors) => ({
//         ...prevErrors,
//         [name]: err.message,
//       }));
//     }
//   };

//   const handleDeleteTicketData = (ticketIndex, subTicketIndexToDelete) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       EventTicket: prevState.EventTicket.map((ticket, i) => {
//         if (ticketIndex !== i) return ticket;
//         return {
//           ...ticket,
//           Ticketdata: ticket.Ticketdata.filter(
//             (_, j) => j !== subTicketIndexToDelete
//           ),
//         };
//       }),
//     }));
//   };

//   const handleDeleteDateData = (ticketIndex) => {
//     setFormData((prevState) => ({
//       ...prevState,
//       EventTicket: prevState.EventTicket.filter((_, i) => i !== ticketIndex),
//     }));
//   };

//   const handleAddTicketData = (index) => {
//     setFormData((prevState) => {
//       const updatedEventTicket = prevState.EventTicket.map((ticket, i) => {
//         if (i === index) {
//           return {
//             ...ticket,
//             Ticketdata: [
//               ...ticket.Ticketdata,
//               { tname: "", tamt: "", tqty: "" },
//             ],
//           };
//         }
//         return ticket;
//       });
//       return { ...prevState, EventTicket: updatedEventTicket };
//     });
//   };

//   const getMinDateTime = () => {
//     const now = new Date();
//     const minDate = now.toISOString().split("T")[0]; // Get YYYY-MM-DD format
//     return minDate;
//   };

//   const handleAddEventTicket = () => {
//     setFormData((prevState) => ({
//       ...prevState,
//       EventTicket: [
//         ...prevState.EventTicket,
//         {
//           EventDate: "",
//           EventStartTime: "",
//           EventEndTime: "",
//           Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
//         },
//       ],
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsFormLoading(true);
//     try {
//       await validationSchema.validate(formData, { abortEarly: false });
//       const data = await AddEventAddress(formData);
//       if (data.status === true) {
//         navigate("/your-listing");
//       }
//     } catch (error) {
//       if (error instanceof Yup.ValidationError) {
//         const validationErrors = {};
//         error.inner.forEach((err) => {
//           validationErrors[err.path] = err.message;
//         });
//         setFormErrors(validationErrors);
//       } else {
//         console.error("Submission Error:", error);
//       }
//     } finally {
//       setIsFormLoading(false);
//     }
//   };

//   const AddNewAddress = async (e) => {
//     e.preventDefault();
//     setIsFormLoading(true);
//     try {
//       await validationSchema.validate(formData, { abortEarly: false });
//       const data = await AddEventAddress(formData);
//       if (data.status === true) {
//         // Clearing specific fields and setting others
//         setFormData({
//           ...InitialFormData,
//           EventId: InitialFormData.EventId, // Setting EventId from InitialFormData
//           Country: InitialFormData.Country, // Setting Country from InitialFormData
//           CountryId: InitialFormData.CountryId, // Setting CountryId from InitialFormData
//           CityId: "",
//           LanguageId: "",
//           Duration: "",
//           Type: "",
//           Location: "",
//           Address: "",
//           EventTicket: [
//             {
//               EventDate: "",
//               EventStartTime: "",
//               EventEndTime: "",
//               Ticketdata: [{ tname: "", tamt: "", tqty: "" }],
//             },
//           ],
//         });
//       }
//     } catch (error) {
//       if (error instanceof Yup.ValidationError) {
//         const validationErrors = {};
//         error.inner.forEach((err) => {
//           validationErrors[err.path] = err.message;
//         });
//         setFormErrors(validationErrors);
//       } else {
//         console.error("Submission Error:", error);
//       }
//     } finally {
//       setIsFormLoading(false);
//     }
//   };
//   return (
//     <div className=" dark:bg-boxdark-2 dark:text-white">
//       <ScrollToTop />
//       {isFormLoading && <FormLoader loading={isFormLoading} />}
//       <div className="w-full container mx-auto">
//         <div className="w-full  lg:grid grid-cols-5 pb-6">
//           <DashboardMenu />

//           {/* ===============================Nav Pilss data ====================================*/}
//           <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
//             <div className=" flex-auto">
//               <div className=" px-2">
//                 <div className="bg-white  dark:bg-boxdark-2 dark:text-white border dark:border-zinc-600 p-5">
//                   <div className="border-b flex items-center bg-themecolor2 mb-2">
//                     <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
//                       Add Address
//                     </h1>
//                   </div>

//                   <form onSubmit={handleSubmit}>
//                     <h1 className="text-lg p-2 py-1 mt-4 text-white font-bold bg-themecolor1 container mx-auto">
//                       Event Details <span className="text-red-600">*</span>
//                     </h1>
//                     <div className="container mx-auto border my-2 p-4">
//                       <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
//                         <div>
//                           <h1 className="font-semibold py-2">
//                             Country <span className="text-red-600">*</span>
//                           </h1>
//                           <select
//                             name="CountryId"
//                             disabled
//                             value={formData.CountryId}
//                             onChange={(e) => handleInputChange(e)}
//                             className="form-select border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                           >
//                             {StateData?.map((val, index) => {
//                               return (
//                                 <option value={val.Id} key={index}>
//                                   {val.Title}
//                                 </option>
//                               );
//                             })}
//                           </select>
//                           {formErrors.CountryId && (
//                             <p className="text-red-500 text-sm">
//                               {formErrors.CountryId}
//                             </p>
//                           )}
//                         </div>
//                         <div>
//                           <h1 className="font-semibold py-2">
//                             City <span className="text-red-600">*</span>
//                           </h1>
//                           <select
//                             name="CityId"
//                             value={formData.CityId}
//                             onChange={(e) => handleInputChange(e)}
//                             className="form-select border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                           >
//                             <option value="">Select City</option>
//                             {CityData?.map((val, index) => (
//                               <option value={val.Id} key={index}>
//                                 {val.Title}
//                               </option>
//                             ))}
//                           </select>
//                           {formErrors.CityId && (
//                             <p className="text-red-500 text-sm">
//                               {formErrors.CityId}
//                             </p>
//                           )}
//                         </div>

//                         <div>
//                           <h1 className="font-semibold py-2">
//                             Event Language{" "}
//                             <span className="text-red-600">*</span>
//                           </h1>
//                           <select
//                             name="LanguageId"
//                             value={formData.LanguageId}
//                             onChange={(e) => handleInputChange(e)}
//                             className="form-select border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                           >
//                             <option value="">Select Language</option>
//                             {LanguageData?.map((val, index) => {
//                               return (
//                                 <option value={val.Id} key={index}>
//                                   {val.Title}
//                                 </option>
//                               );
//                             })}
//                           </select>
//                           {formErrors.LanguageId && (
//                             <p className="text-red-500 text-sm">
//                               {formErrors.LanguageId}
//                             </p>
//                           )}
//                         </div>
//                         <div>
//                           <h1 className="font-semibold py-2">
//                             Event Duration{" "}
//                             <span className="text-red-600">*</span>
//                           </h1>
//                           <input
//                             type="text"
//                             name="Duration"
//                             value={formData.Duration}
//                             onChange={(e) => handleInputChange(e)}
//                             className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                             placeholder="Event Duration"
//                           />
//                           {formErrors.Duration && (
//                             <p className="text-red-500 text-sm">
//                               {formErrors.Duration}
//                             </p>
//                           )}
//                         </div>
//                         <div>
//                           <h1 className="font-semibold py-2">
//                             Select Type of Event{" "}
//                             <span className="text-red-600">*</span>
//                           </h1>
//                           <select
//                             id="type"
//                             name="Type"
//                             value={formData.Type}
//                             onChange={(e) => handleInputChange(e)}
//                             className="form-select border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                           >
//                             <option value="">Select Type of Event</option>
//                             <option value="Indoor">Indoor</option>
//                             <option value="Outdoor">Outdoor</option>
//                           </select>
//                           {formErrors.Type && (
//                             <p className="text-red-500 text-sm">
//                               {formErrors.Type}
//                             </p>
//                           )}
//                         </div>

//                         <div>
//                           <h1 className="font-semibold py-2">
//                             Location <span className="text-red-600">*</span>
//                           </h1>
//                           <input
//                             type="text"
//                             value={formData.Location}
//                             onChange={(e) => handleInputChange(e)}
//                             name="Location"
//                             className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                             placeholder="Location"
//                           />
//                           {formErrors.Location && (
//                             <p className="text-red-500 text-sm">
//                               {formErrors.Location}
//                             </p>
//                           )}
//                         </div>
//                       </div>
//                       <div>
//                         <h1 className="font-semibold py-2">
//                           Location Iframe{" "}
//                           <span className="text-red-600">*</span>
//                         </h1>
//                         <textarea
//                           name="Address"
//                           value={formData.Address}
//                           onChange={(e) => handleInputChange(e)}
//                           placeholder="Location Iframe"
//                           className="form-select border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                         ></textarea>
//                         {formErrors.Address && (
//                           <p className="text-red-500 text-sm">
//                             {formErrors.Address}
//                           </p>
//                         )}
//                       </div>
//                     </div>
//                     {/* EventTicket */}
//                     {formData.EventTicket.map((ticket, index) => (
//                       <div
//                         key={index}
//                         className="container mx-auto border p-4 my-6"
//                       >
//                         <div className="flex flex-col md:flex-row md:justify-between justify-start md:items-center items-start mb-4">
//                           <p className="m-0 border border-themecolor1 py-1.5 rounded-3xl">
//                             <span className="bg-themecolor1  text-white px-3.5 py-2 rounded-full mr-2">
//                               {index + 1}
//                             </span>
//                             <span className="pr-3">
//                               Event Ticket {index + 1}
//                             </span>
//                           </p>

//                           <div className="flex items-center md:mt-0 mt-2">
//                             <button
//                               type="button"
//                               onClick={() => handleAddEventTicket()}
//                               className="bg-green-500 py-2 px-5 flex items-center text-white"
//                             >
//                               Add Event Ticket
//                             </button>
//                             <button
//                               type="button"
//                               onClick={() => handleDeleteDateData(index)}
//                               className="bg-red-500 text-white px-4 py-3 rounded-md ml-2"
//                             >
//                               <FaTrash />
//                             </button>
//                           </div>
//                         </div>
//                         <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
//                           <div>
//                             <h1 className="font-semibold py-2">
//                               Event Date <span className="text-red-600">*</span>
//                             </h1>
//                             <input
//                               type="date"
//                               name="EventDate"
//                               required
//                               value={ticket.EventDate}
//                               onChange={(e) => handleInputChange(e, index)}
//                               className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                               placeholder="Event Date"
//                               min={getMinDateTime()}
//                             />
//                             {formErrors[`EventTicket[${index}].EventDate`] && (
//                               <p className="text-red-500 mt-1">
//                                 {formErrors[`EventTicket[${index}].EventDate`]}
//                               </p>
//                             )}
//                           </div>
//                           <div>
//                             <h1 className="font-semibold py-2">
//                               Event Start Time{" "}
//                               <span className="text-red-600">*</span>
//                             </h1>
//                             <input
//                               type="time"
//                               name="EventStartTime"
//                               required
//                               value={ticket.EventStartTime}
//                               onChange={(e) => handleInputChange(e, index)}
//                               className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                               placeholder="Event Date"
//                             />
//                             {formErrors[
//                               `EventTicket[${index}].EventStartTime`
//                             ] && (
//                               <p className="text-red-500 mt-1">
//                                 {
//                                   formErrors[
//                                     `EventTicket[${index}].EventStartTime`
//                                   ]
//                                 }
//                               </p>
//                             )}
//                           </div>
//                           <div>
//                             <h1 className="font-semibold py-2">
//                               Event End Time{" "}
//                               <span className="text-red-600">*</span>
//                             </h1>
//                             <input
//                               type="time"
//                               required
//                               name="EventEndTime"
//                               value={ticket.EventEndTime}
//                               onChange={(e) => handleInputChange(e, index)}
//                               className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                               placeholder="Event Date"
//                             />
//                             {formErrors[
//                               `EventTicket[${index}].EventEndTime`
//                             ] && (
//                               <p className="text-red-500 mt-1">
//                                 {
//                                   formErrors[
//                                     `EventTicket[${index}].EventEndTime`
//                                   ]
//                                 }
//                               </p>
//                             )}
//                           </div>
//                         </div>
//                         {ticket.Ticketdata.map((subTicket, subIndex) => (
//                           <div
//                             key={subIndex}
//                             className="container mx-auto grid lg:grid-cols-10 md:grid-cols-7 grid-cols-1 gap-3 border dark:border-zinc-600 p-2 my-6 relative"
//                           >
//                             <div className="lg:col-span-3 md:col-span-2">
//                               <h1 className="font-semibold py-2">
//                                 Ticket Name{" "}
//                                 <span className="text-red-600">*</span>
//                               </h1>
//                               <input
//                                 type="text"
//                                 required
//                                 id={`tname${subIndex}`}
//                                 name="tname"
//                                 value={subTicket.tname}
//                                 onChange={(e) =>
//                                   handleInputChange(e, index, subIndex)
//                                 }
//                                 className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                                 placeholder="Ticket Name"
//                               />
//                               {formErrors[
//                                 `EventTicket[${index}].Ticketdata[${subIndex}].tname`
//                               ] && (
//                                 <p className="text-red-500 mt-1">
//                                   {
//                                     formErrors[
//                                       `EventTicket[${index}].Ticketdata[${subIndex}].tname`
//                                     ]
//                                   }
//                                 </p>
//                               )}
//                             </div>
//                             <div className="lg:col-span-3 md:col-span-2">
//                               <h1 className="font-semibold py-2 flex items-center">
//                                 Ticket Price{" "}
//                                 {CountryName === "India" ? (
//                                   <>
//                                     (
//                                     <span className="text-sm">
//                                       <FaRupeeSign />
//                                     </span>
//                                     )
//                                   </>
//                                 ) : (
//                                   <>
//                                     (
//                                     <span className="text-sm">
//                                       <FaDollarSign />
//                                     </span>
//                                     )
//                                   </>
//                                 )}
//                                 <span className="text-red-600">*</span>
//                               </h1>
//                               <input
//                                 type="number"
//                                 required
//                                 min="0"
//                                 id={`tamt${subIndex}`}
//                                 name="tamt"
//                                 value={subTicket.tamt}
//                                 onChange={(e) =>
//                                   handleInputChange(e, index, subIndex)
//                                 }
//                                 className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                                 placeholder="Ticket Price"
//                               />
//                               {formErrors[
//                                 `EventTicket[${index}].Ticketdata[${subIndex}].tamt`
//                               ] && (
//                                 <p className="text-red-500 mt-1">
//                                   {
//                                     formErrors[
//                                       `EventTicket[${index}].Ticketdata[${subIndex}].tamt`
//                                     ]
//                                   }
//                                 </p>
//                               )}
//                             </div>
//                             <div className="lg:col-span-3 md:col-span-2">
//                               <h1 className="font-semibold py-2">
//                                 Ticket Quantity{" "}
//                                 <span className="text-red-600">*</span>
//                               </h1>
//                               <input
//                                 type="number"
//                                 required
//                                 min="0"
//                                 id={`tqty${subIndex}`}
//                                 name="tqty"
//                                 value={subTicket.tqty}
//                                 onChange={(e) =>
//                                   handleInputChange(e, index, subIndex)
//                                 }
//                                 className="border dark:border-zinc-600 w-full p-2 rounded-sm dark:bg-boxdark-2 dark:text-white"
//                                 placeholder="Ticket Quantity"
//                               />
//                               {formErrors[
//                                 `EventTicket[${index}].Ticketdata[${subIndex}].tqty`
//                               ] && (
//                                 <p className="text-red-500 mt-1">
//                                   {
//                                     formErrors[
//                                       `EventTicket[${index}].Ticketdata[${subIndex}].tqty`
//                                     ]
//                                   }
//                                 </p>
//                               )}
//                             </div>
//                             <div className="lg:col-span-1 md:col-span-1">
//                               <h1 className="font-semibold py-2">Action</h1>
//                               <button
//                                 type="button"
//                                 onClick={() =>
//                                   handleDeleteTicketData(index, subIndex)
//                                 }
//                                 className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
//                               >
//                                 <FaTrash />
//                               </button>
//                             </div>
//                             <h1 className="absolute bg-themecolor2 text-white px-2 -top-3">
//                               {subIndex + 1}
//                             </h1>
//                           </div>
//                         ))}
//                         <div className="flex">
//                           <button
//                             type="button"
//                             onClick={() => handleAddTicketData(index)}
//                             className="bg-green-500 py-2 px-5 flex items-center text-white"
//                           >
//                             Add Ticket
//                           </button>
//                         </div>
//                       </div>
//                     ))}

//                     <div className="flex flex-col md:flex-row md:justify-between justify-start md:items-center items-start">
//                       <button
//                         type="submit"
//                         className="bg-[#EC5E71] block md:my-5 placeholder:my-2 text-white font-semibold px-12 p-2 rounded-md md:mr-auto foldsmall:mx-auto"
//                       >
//                         Submit
//                       </button>
//                       <button
//                         type="button"
//                         onClick={AddNewAddress}
//                         className="bg-[#EC5E71] block md:my-5 my-2 text-white font-semibold px-12 p-2 rounded-md md:ml-auto foldsmall:mx-auto"
//                       >
//                         Save And Continue
//                       </button>
//                     </div>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddAddress;
